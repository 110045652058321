import React from 'react'
import {NavLink} from "react-router-dom";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './Navigation.css'
import logo from '../../../assets/static/imgs/main_logo.png';

class Navigation extends React.Component {

    handleMobileNavClick = () => {
        document.getElementById(".app-mobile-nav-checkbox").checked = false;
    }

    render() {
        return (
            <div className="app-navigation">
                <div className="app-mobile-nav">
                    <input id="mobile-nav-checkbox" type="checkbox" />

                    <span/>
                    <span/>
                    <span/>

                    <ul className="menu">
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/">Home</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/after-school-sessions">After School Sessions</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/private-play-sessions">Private Play Sessions</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/general-play-sessions">General Play Sessions</NavLink>
                        {/* <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/gallery">Gallery</NavLink> */}
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/cancellation-policy">Cancellation Policy</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/about-us">About Us</NavLink>
                    </ul>
                </div>

                <div className="nav-options-container">
                    <ul className="menu-options">
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/">Home</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/after-school-sessions">After School Sessions</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/private-play-sessions">Private Play Sessions</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/general-play-sessions">General Play Sessions</NavLink>
                        {/* <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/gallery">Gallery</NavLink> */}
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/cancellation-policy">Cancellation Policy</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/about-us">About Us</NavLink>
                    </ul>
                </div>

                <div className='logo-container'>
                    <NavLink to="/"><img className="logo-img" src={logo} alt="Logo" /></NavLink>
                </div>

            </div>
        );
    }
}

export default Navigation;