import React, { Component } from "react";
import './Toggle.css'

class Toggle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_option: ""
        }

      }

    onToggle = (option) => {
        this.props.handleChange(option.target.innerText)
        this.setState({
            selected_option: option.target.innerText
        })
    }

    checkSelectedState = () => {
        let red = ""
        let green = ""
        if (this.state.selected_option === "Yes") {
            green = " green-checked"
        } else if (this.state.selected_option === "No") {
            red = " red-checked"
        }
        return {green_checked: green, red_checked: red}
    }

    render() {
        let checked = this.checkSelectedState()

        return (
            <div className="btn-group toggle-group">
                <div className="option-container">
                    <label className={"btn btn-primary btn-toggle btn-green" + checked['green_checked']} onClick={this.onToggle}>Yes</label>
                </div>
                <div className="option-container">
                    <label className={"btn btn-primary btn-toggle btn-red" + checked['red_checked']} onClick={this.onToggle}>No</label>
                </div>
            </div>
        );
    }
}

export default Toggle;