import React from "react";
import './NotFound.css';
import Navigation from "../common/navigation/Navigation";

/**
 * The 404 page.
 *
 * Notifies the user that the page they requested could not be found.
 *
 * @author Tom Mowbray - w17020085
 */
class NotFoundPage extends React.Component {

    render() {
        return (
            <div className="app-page">
                <Navigation />
                <h1>404 - Page Not Found</h1>
            </div>
        )
    }

}

export default NotFoundPage;