import React, {Component} from 'react'
import './Buttons.css'
import {NavLink} from "react-router-dom";

class Buttons extends Component {

    constructor(props) {
        super(props);
    }

    checkButtonDisabled = () => {
        return (this.props.disabled !== undefined && this.props.disabled) ? "disabled" : ""
    }

    generateButton = () => {
        return (this.props.link)
            ? <NavLink className="button-link" to={this.props.to}>{this.props.btn_text}</NavLink>
            : <p className={"button-link " + this.checkButtonDisabled()}>{this.props.btn_text}</p>;
    }

    render() {
        let button = this.generateButton()

        return (
            <div className="button-container">
                {button}
            </div>
        )
    }

}

export default Buttons;