import React, {Component} from 'react';
import Home from "./home/Home";
import Navigation from "./common/navigation/Navigation";
import BrokerHome from "./broker/BrokerHome";
import GalleryHome from "./gallery/GalleryHome";
import AboutUsHome from "./about-us/AboutUsHome";
import './WebApp.css'
import BookingConf from "./booking-conf/BookingConf";
import CancellationPolicy from './cancellation-policy/CancellationPolicyHome';

class AppRouter extends Component {

    generatePage = () => {
        let page;
        let page_container = "app-page ";

        if (this.props.page === "after-school-sessions" || this.props.page === "private-play-sessions" || this.props.page === "general-play-sessions") {
            page = <BrokerHome type={this.props.page} />
            page_container += "broker-page"
        }// else if (this.props.page === "gallery") {
        //     page = <GalleryHome />
        //     page_container += "gallery-page"
        // }
        else if (this.props.page === "about-us") {
            page = <AboutUsHome />
            page_container += "about-us-page"
        }
        else if (this.props.page === "cancellation-policy") {
            page = <CancellationPolicy />
            page_container += "about-us-page"
        } else if (this.props.page === "booking-conf") {
            page = <BookingConf />
            page_container += "booking-conf-page"
        } else {
            page = <Home />
            page_container += "home-page"
        }

        return {page: page, page_container: page_container}
    }

    render() {
        let page_data = this.generatePage()

        //todo something needs to be done when only one row of cards (use controlled components to switch between absolute and current (similar to home screen when only 1 row))

        return (
            <div className={page_data['page_container']}>
                <Navigation />
                {page_data['page']}
            </div>
        );
    }

}

export default AppRouter;