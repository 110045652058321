import React from 'react';
import './Bookings.css';
import {randomString, setPageContentMargin} from "../../../Utils";
import DataGrid from "../common/data-grid/DataGrid";
import Booking from "./Booking";
import PageHeader from "../common/page-header/PageHeader";
import Button from "react-bootstrap/Button";
import Notification from "../common/notification/Notification";

class BookingsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// url: "http://localhost/rfk-rest/rfk-api/api/orders",
			url: "https://api.repeatforkids.co.uk/api/orders",
			page: 'home',
			loading: true,
			table_data: [],
			booking_data: [],
			selected_booking: "",
			success: ""
		};
	}

	componentDidMount() {
		setPageContentMargin();
		this.getOrders();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		setPageContentMargin();
		if (prevState.selected_booking !== this.state.selected_booking) {
			if (this.state.selected_booking !== "" && this.state.selected_booking !==0) {
				this.getOrderDetails(this.state.selected_booking);
			} else {
				this.getOrders();
			}
		}
	}

	getOrders = () => {
		if (localStorage.getItem('aawt')) {
			let formData = new FormData();
			formData.append('action', 'get-orders');
			formData.append('token', localStorage.getItem('aawt'))
			formData.append('table_view', 1);
			formData.append('recent', (this.props.recent) ? 1 : 0)

			fetch(this.state.url, {
				method: 'POST',
				headers: new Headers(),
				body: formData
			}).then( (response) => {
				if (response.status === 200) {
					return response.json();
				} else {
					throw Error();
				}
			}).then( (data) => {
				this.prepareDataForTable(data.data)
			}).catch( (e) => {
				this.setState({
					error: "Something went wrong. Please try again later."
				});
			});
		} else {
			// alert("handle no auth error");
		}
	}

	getOrderDetails = (order_id) => {
		let formData = new FormData();
		formData.append('action', 'get-order-details');
		formData.append('order_id', order_id)

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			this.setState({
				page: "view_booking",
				table_data: [],
				booking_data: data.data,
				success: ""
			})
		}).catch( () => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});
	}

	prepareDataForTable = (orders_data) => {

		let columns = [
			{ name: "Parent", selector: row => row.parent, minWidth: '150px', sortable: true, isDate: false },
			{ name: "Child Num", selector: row => row.num, sortable: true, isDate: false },
			{ name: "Session Date", selector: row => row.session, sortable: true, isDate: true },
			{ name: "Booking Date", selector: row => row.date, sortable: true, isDate: true },
		];


		let data = [];
		for (let order_data in orders_data) {
			if (orders_data[order_data].order_date !== "") {
		// 		convert date from yyyy-mm-dd to dd/mm/yy
				let datePart = orders_data[order_data].order_completed.match(/\d+/g);
				let year = datePart[0].substring(2);
				let month = datePart[1], day = datePart[2];
				orders_data[order_data].order_completed = day + '/' + month + '/' + year;

				// do same for the session date
				let datePart2 = orders_data[order_data].session_date.match(/\d+/g);
				let year2 = datePart2[0].substring(2);
				let month2 = datePart2[1], day2 = datePart2[2];
				orders_data[order_data].session_date = day2 + '/' + month2 + '/' + year2;
			}

			data.push({
				id: orders_data[order_data].order_id,
				parent: orders_data[order_data].order_parent,
				num: orders_data[order_data].order_child_num,
				session: orders_data[order_data].session_date,
				date: orders_data[order_data].order_completed,
			});

		}

		this.setState({
			table_data: [columns, data],
			loading: false
		});

	}

	handleRowClick = (row) => {
			this.setState({
				selected_booking: row.id,
				success: ""
			});
	}

	handleBackClick = () => {
		this.resetPage()
	}

	handleDeletedOrder = () => {
		this.setNotification()
		this.resetPage();
	}

	handleDeleteAll = () => {
		if (localStorage.getItem('aawt')) {
			let code = randomString(4)
			let input = window.prompt("Enter the confirmation code: " + code);

			if (code === input) {
				let formData = new FormData();
				formData.append('action', 'delete-all');
				formData.append('token', localStorage.getItem('aawt'))

				fetch(this.state.url, {
					method: 'POST',
					headers: new Headers(),
					body: formData
				}).then((response) => {
					if (response.status === 200) {
						return response.json();
					} else {
						throw Error();
					}
				}).then((data) => {
					if (data.statusCode === 200) {
						this.getOrders();
					} else {
						//todo handle this error
						throw Error()
					}
				}).catch((err) => {
					console.log(err)
					this.setState({
						error: "Something went wrong. Please try again later."
					});
				});

			}
		} else {
			// alert("handle no auth error");
		}
	}

	resetPage = () => {
		this.setState({
			page: "home",
			selected_booking: "",
		})
	}

	setNotification = () => {
		this.setState({
			success: true
		})
	}

	render() {

		let page_title;
		let page_content;
		let show_back;
		let sub_heading = false
		let styling = "page-container";
		if (this.state.page === "home") {
			sub_heading = (this.props.recent) ? true : sub_heading;
			page_title = (this.props.recent) ? 'Recent Booking Activity' : 'Bookings';
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			let del_all_button = (this.props.recent) ? "" : <Button className="del-all-btn" variant="primary" onClick={this.handleDeleteAll}>Delete All Orders</Button>;
			page_content = (<div><DataGrid
					columns={this.state.table_data[0]}
					data={this.state.table_data[1]}
					loading={this.state.loading}
					handleRowClick={this.handleRowClick}
			/>{del_all_button}</div>);
			show_back = false;

		} else if (this.state.page === "view_booking" && this.state.selected_booking !== "")  {
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			page_title = "View Booking"
			page_content = <Booking action="view" booking={this.state.booking_data} handleDeletedOrder={this.handleDeletedOrder}/>
			show_back = true;
		}

		return(
			<div className={styling}>
				<PageHeader
					sub_heading={sub_heading}
					page_title={page_title}
					show_back={show_back}
					handleBackClick={this.handleBackClick}
				/>
				<div className="page-content">
					{page_content}
				</div>
			</div>
        );
	}

}

export default BookingsPage;