import React from 'react';
import './PageHeader.css';
import {IoIosArrowRoundBack} from "react-icons/io";

class PageHeader extends React.Component {

    render() {

        let back_button;
        if (this.props.show_back) {
            back_button = <div className="back-arrow-container"><IoIosArrowRoundBack className="back-arrow" onClick={this.props.handleBackClick} /></div>
        }

        let heading_class_type;
        if (this.props.sub_heading) {
            heading_class_type = "page-sub-header"
        } else {
            heading_class_type = "page-header"
        }


        return (
            <div className={heading_class_type}>
                {back_button}
                <div className="heading-container">
                    <h1 className="page-heading">{this.props.page_title}</h1>
                </div>
            </div>
        );
    }

}

export default PageHeader;