import React from 'react'
import SideNav, {Nav, NavIcon, NavItem, NavText, Toggle} from '@trendmicro/react-sidenav';
import {NavLink} from "react-router-dom";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './Navigation.css'
import {
    BsFillCalendar3EventFill,
    BsFillClockFill,
    BsFillImageFill,
    BsHouseFill,
    BsBookFill,
    BsEmojiSmileFill
} from 'react-icons/bs'
import { FaWarehouse } from "react-icons/fa6";


class Navigation extends React.Component {

    handleClick = () => {
        let page_container = document.getElementsByClassName('page-container')[0];
        let options = document.getElementsByClassName('nav-text');

        // set the page content margin to width of menu if open & unset if closed
        if (page_container.style.marginLeft === "240px") {
            page_container.style.marginLeft = "64px";
        } else {
            page_container.style.marginLeft = "240px";
        }

        // show the nav option text if menu open and hide if menu closed
        for(let i = 0; i < options.length; i++) {
            if (options[i].style.display === "block") {
                options[i].style.display = 'none';
            } else {
                options[i].style.display = 'block';
            }
        }
    }

    handleMobileNavClick = () => {
        document.getElementById("mobile-nav-checkbox").checked = false;
    }

    render() {
        return (
            <div className="navigation">
                <div className="mobile-nav">
                    <input id="mobile-nav-checkbox" type="checkbox" />

                    <span/>
                    <span/>
                    <span/>

                    <ul className="menu">
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin">Dashboard</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-bookings">Bookings</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-sessions">Sessions</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-private-hires">Private Play Session</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-general-play-sessions">General Play</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-reviews">Reviews</NavLink>
                        <NavLink onClick={this.handleMobileNavClick} className="nav-link" to="/admin-gallery">Gallery</NavLink>
                    </ul>
                </div>

                <SideNav>

                    <Toggle onClick={this.handleClick}/>

                    <Nav defaultSelected="dashboard">

                        <NavLink to={"/admin"}>
                            <NavItem eventKey="dashboard">
                                <NavIcon><BsHouseFill /></NavIcon>
                                <NavText><p className="nav-text">Dashboard</p></NavText>
                            </NavItem>
                        </NavLink>

                        <NavLink to={"/admin-bookings"}>
                            <NavItem eventKey="bookings">
                                <NavIcon><BsBookFill /></NavIcon>
                                <NavText><p className="nav-text">Bookings</p></NavText>
                            </NavItem>
                        </NavLink>

                        <NavLink to={"/admin-sessions"}>
                            <NavItem eventKey="sessions">
                                <NavIcon><BsFillClockFill /></NavIcon>
                                <NavText><p className="nav-text">Sessions</p></NavText>
                            </NavItem>
                        </NavLink>

                        <NavLink to={"/admin-private-hires"}>
                            <NavItem eventKey="toddler-sessions">
                                <NavIcon><FaWarehouse  /></NavIcon>
                                <NavText><p className="nav-text">Private Hire</p></NavText>
                            </NavItem>
                        </NavLink>

                        <NavLink to={"/admin-general-play-sessions"}>
                            <NavItem eventKey="events">
                                <NavIcon><BsFillCalendar3EventFill /></NavIcon>
                                <NavText><p className="nav-text">General Play Sessions</p></NavText>
                            </NavItem>
                        </NavLink>


                        <NavLink to={"/admin-reviews"}>
                            <NavItem eventKey="review">
                                <NavIcon><BsEmojiSmileFill /></NavIcon>
                                <NavText><p className="nav-text">Reviews</p></NavText>
                            </NavItem>
                        </NavLink>

                        <NavLink to={"/admin-gallery"}>
                            <NavItem eventKey="gallery">
                                <NavIcon><BsFillImageFill /></NavIcon>
                                <NavText><p className="nav-text">Gallery</p></NavText>
                            </NavItem>
                        </NavLink>


                    </Nav>

                </SideNav>
            </div>
        );
    }
}

export default Navigation;