import React from "react";
import { useState, useEffect } from 'react'
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import './DataGrid.css'
import SyncLoader from "react-spinners/SyncLoader";

const DataGrid = ({ data, columns, loading, handleRowClick }) => {

    const [cols, setCols] = useState();

    const getPropByIndex = (obj, index) => {
        const values = Object.values(obj);
        return values[index]; // Replace 'index' with the numerical index
    }

    const convertToDate = (str) => {
        const parts = str.split("/");
    
        // Parts are assumed to be in dd/mm/yy format
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are 0-indexed in JavaScript
        const year = parseInt(parts[2], 10);
    
        // Handle two-digit year (assuming 2000s)
        const fullYear = year < 100 ? 2000 + year : year;
    
        return new Date(fullYear, month, day);
    }

    const sortByDate = (index) => (rowA, rowB) => {
        const dateA = convertToDate(getPropByIndex(rowA, index))
        const dateB = convertToDate(getPropByIndex(rowB, index))

        if (dateA > dateB) {
            return 1;
        }

        if (dateA < dateB) {
            return -1
        }

        return 0
    }

    const configureCols = () => {
        let index = 1;
        columns.forEach((col) => {
            if (col.isDate) {
                col.sortFunction = sortByDate(index)
            }
            index++
        })
        setCols(columns)
    }

    useEffect(() => {
        if (!loading && columns !== undefined) {
            configureCols();
        }
    }, [loading, columns])



    return (
        <div className="data-table-container">
            <DataTable
                columns={cols}
                data={data}
                noHeader
                striped={true}
                defaultSortField="id"
                defaultSortAsc={false}
                so
                pagination
                highlightOnHover
                pointerOnHover
                progressPending={loading}
                progressComponent={<SyncLoader color='lightgrey' loading={loading} css='margin: 40px 0' size={7} />}
                onRowClicked={handleRowClick}
            />
        </div>
    );
}

export default DataGrid;
