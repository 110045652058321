import React from "react";
import ReviewForm from "./ReviewForm";
import {setPageContentMargin} from "../../../Utils";

class Review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/events",
            url: "https://api.repeatforkids.co.uk/api/events",
            event_id: "",
            event_name: "",
            event_date: "",
            event_time: "",
            event_price: "",
            event_reserved: "",
            event_booked: "",
            event_max: "",
            event_image: "",
            event_image_name: "",
            allowed_empty: ['event_image', 'event_image_name', 'event_booked'],
            error: "",
            error_fields: [],
        };
    }

    componentDidMount() {
        setPageContentMargin();
        if (this.props.action === "edit") {
            this.setState({
                event_id: this.props.event[0].session_id,
                event_name: this.props.event[0].session_name,
                event_date: this.props.event[0].session_date,
                event_time: this.props.event[0].session_time,
                event_price: this.props.event[0].session_price,
                event_reserved: this.props.event[0].session_reserved,
                event_booked: this.props.event[0].session_booked,
                event_max: this.props.event[0].session_max,
                event_image: this.props.event[0].session_image,
                event_image_name: this.props.event[0].session_image_name,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setPageContentMargin();
    }

    handleEventNameChange = (name) => {
        this.setState({event_name: name.target.value})
    }

    handleEventImageChange = (image) => {
        let files = image.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (img) => {
            this.setState({
                event_image: img.target.result,
            })
        }
    }

    handleEventDateChange = (date) => {
        this.setState({event_date: date.target.value})
    }

    handleEventTimeChange = (time) => {
        this.setState({event_time: time.target.value})
    }

    handleEventPriceChange = (price) => {
        this.setState({event_price: price.target.value})
    }

    handleEventMaxChange = (max) => {
        this.setState({event_max: max.target.value})
    }

    handleEventReservedChange = (reserved) => {
        this.setState({event_reserved: reserved.target.value})
    }

    prepareFormData = () => {
        let name = this.state.event_name;
        let date = this.state.event_date;
        let time = this.state.event_time;
        let price = this.state.event_price;
        let reserved = this.state.event_reserved;
        let booked = this.state.event_booked;
        let max = this.state.event_max;
        let image = this.state.event_image;
        let image_name = this.state.event_image_name;

        if (image === undefined) {
            image = ""
        }

        let data = {
            'name': name,
            'date': date,
            'time': time,
            'price': price,
            'reserved': reserved,
            'booked': booked,
            'max': max,
            'image': image,
            'image_name': image_name,
        }

        return data;
    }

    doSave = (create, saveExit) => {
        if (localStorage.getItem('aawt')) {
            let data = this.prepareFormData()
            let formData = new FormData();

            formData.append('action', (create) ? "new-event" : "save-event");
            formData.append('token', localStorage.getItem('aawt'));
            formData.append('event_id', this.state.event_id)
            formData.append('event_name', data.name)
            formData.append('event_date', data.date)
            formData.append('event_time', data.time)
            formData.append('event_price', data.price)
            formData.append('event_reserved', data.reserved)
            formData.append('event_booked', data.booked)
            formData.append('event_max', data.max)
            formData.append('event_image', data.image)
            formData.append('event_img_name', data.image_name)

            fetch(this.state.url, {
                method: 'POST',
                headers: new Headers(),
                body: formData
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error();
                }
            }).then((data) => {
                if (data.statusCode === 200) {
                    if (create && !saveExit) {
                        this.props.handleNewEventSave(data.data.event_id);
                    } else {
                        if (saveExit) {
                            this.props.handleSaveExit();
                        }
                    }
                } else {
                    throw Error();
                }
            }).catch((e) => {
                console.log(e.toString())
                // todo handle this and other errors/point of failure
                this.setState({
                    error: "Something went wrong. Please try again later."
                });
            });
        } else {
            // alert("handle no auth error");
        }
    }

    checkFormValues = () => {
        let form_error = false
        let error_fields = []
        for (let key in this.state) {
            if (key.includes('event_')) {
                if (this.state[key] === "" && key !== "event_id" && !this.state.allowed_empty.includes(key)) {
                    error_fields.push(key)
                    form_error = true
                    window.scrollTo(0, 0)
                } else {
                    error_fields.filter((field) => {
                        return field !== key
                    });
                }
            }
        }

        if (form_error && error_fields.length !== 0) {
            this.setState({
                error: "Please make sure all required fields are filled.",
                error_fields: error_fields
            })
        }

        return form_error
    }

    handleNewSave = () => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(true, false)
            this.props.handleSaveNotification()
        }
    }

    handleNewSaveExit =() => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(true, true);
        }
    }

    handleSave = (e) => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(false, false)
            this.props.handleSaveNotification()
        }
    }

    handleSaveExit = (e) => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(false, true)
        }
    }

    handleDelete = (e) => {
        if (localStorage.getItem('aawt')) {
            if (window.confirm("Are you sure you want to delete '" + this.state.event_name + "'.\nThis can not be undone")) {
                let formData = new FormData();
                formData.append('action', 'delete-event');
                formData.append('token', localStorage.getItem('aawt'))
                formData.append('event_id', this.state.event_id);

                fetch(this.state.url, {
                    method: 'POST',
                    headers: new Headers(),
                    body: formData
                }).then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw Error();
                    }
                }).then((data) => {
                    if (data.statusCode === 200) {
                        this.props.handleDeletedEvent();
                    } else {
                        //todo handle this error
                        throw Error()
                    }
                }).catch((err) => {
                    console.log(err)
                    this.setState({
                        error: "Something went wrong. Please try again later."
                    });
                });
            }
        }
    }

    handleErrorFieldChange = (field) => {
        if (this.state.error !== "" && this.state.error_fields.length !== 0) {
            if (field.target.value !== "") {
                field.target.style.borderColor = "black";
                this.setState({
                    error_fields: this.state.error_fields.filter((error_field) => {
                        return error_field !== field.target.id.replace('form-', '')
                    })
                })
            }
        }
    }

    render() {

        let show_delete;
        let handleSave;
        let handleSaveExit;
        if (this.props.action === "create") {
            show_delete = false
            handleSave = this.handleNewSave;
            handleSaveExit = this.handleNewSaveExit;
        } else {
            show_delete = true;
            handleSave = this.handleSave;
            handleSaveExit = this.handleSaveExit
        }

        return (
            <div className="page-heading-container">
                <h2 className="sub-heading">{this.state.event_name}</h2>
                <ReviewForm
                    action={this.props.action}
                    id={this.state.event_id}
                    name={this.state.event_name}
                    handleNameChange={this.handleEventNameChange}
                    date={this.state.event_date}
                    handleDateChange={this.handleEventDateChange}
                    time={this.state.event_time}
                    handleTimeChange={this.handleEventTimeChange}
                    price={this.state.event_price}
                    handlePriceChange={this.handleEventPriceChange}
                    reserved={this.state.event_reserved}
                    handleReservedChange={this.handleEventReservedChange}
                    max={this.state.event_max}
                    handleMaxChange={this.handleEventMaxChange}
                    image={this.state.event_image}
                    handleImageChange={this.handleEventImageChange}
                    booked={this.state.event_booked}
                    handleSave={handleSave}
                    handleSaveExit={handleSaveExit}
                    showDelete={show_delete}
                    handleDelete={this.handleDelete}
                    error={this.state.error}
                    error_fields={this.state.error_fields}
                    handleErrorFieldChange={this.handleErrorFieldChange}
                />
            </div>
        );
    }

}

export default Review;
