import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AdminRouter from "./admin/components/AdminRouter";
import NotFoundPage from "./app/components/not-found/NotFoundPage";
import AppRouter from "./app/components/AppRouter";

class App extends Component{

    render() {
        return(
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<AppRouter page="home" />} />
                        <Route path="/after-school-sessions" element={<AppRouter page="after-school-sessions" />} />
                        <Route path="/toddler-sessions" element={<AppRouter page="toddler-sessions" />} />
                        <Route path="/general-play-sessions" element={<AppRouter page="general-play-sessions" />} />
                        <Route path="/private-play-sessions" element={<AppRouter page="private-play-sessions" />} />
                        {/* <Route path="/gallery" element={<AppRouter page="gallery" />} /> */}
                        <Route path="/cancellation-policy" element={<AppRouter page="cancellation-policy" />} />
                        <Route path="/about-us" element={<AppRouter page="about-us" />} />
                        <Route path="/booking-confirmation" element={<AppRouter page="booking-conf" />} />
                        <Route path="/admin" element={<AdminRouter page="dashboard" />} />
                        <Route path="/admin-bookings" element={<AdminRouter page="bookings" />} />
                        <Route path="/admin-sessions" element={<AdminRouter page="sessions" />} />
                        <Route path="/admin-private-hires" element={<AdminRouter page="private-hires" />} />
                        <Route path="/admin-general-play-sessions" element={<AdminRouter page="general-play-sessions" />} />
                        <Route path="/admin-reviews" element={<AdminRouter page="reviews" />} />
                        <Route path="/admin-gallery" element={<AdminRouter page="gallery" />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }

}

export default App;
