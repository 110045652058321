import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import "../common/form-buttons/FormTemplate.css";
import FormButtons from "../common/form-buttons/FormButtons";
import {setPageContentMargin} from "../../../Utils";
import Notification from "../common/notification/Notification";

class ImageForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/gallery",
            url: "https://api.repeatforkids.co.uk/api/gallery",
        }
    }

    componentDidMount() {
        setPageContentMargin();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setPageContentMargin()
    }

    setErrorFields = () => {
        if (this.props.error !== "" && this.props.error_fields.length !== 0) {
            for (let key in this.props.error_fields) {
                let field_id = "form-" + this.props.error_fields[key]
                let field = document.getElementById(field_id)
                if (field !== null) {
                    field.style.borderColor = "red";
                }
            }
            return <Notification type="error" text="Please make sure all required fields are filled" fade={false} form_notification={true}/>
        } else {
            return ""
        }
    }

    render () {

        let error_banner = this.setErrorFields()
        let thumbnail;

        if (this.props.image !== undefined && this.props.image !== "") {
            thumbnail = <img className="img-thumbnail" src={this.props.image} alt="image thumbnail"/>
        } else {
            thumbnail = <p className="no-img">No image selected.</p>
        }

        return(
            <div>
                {error_banner}
                <Container>
                    <Form className="image_form">
                        <Form.Group controlId="form-image_name">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control type="text" name="image_name" placeholder="Image name" value={this.props.name} onChange={this.props.handleNameChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-image_order">
                            <Form.Label>Order*</Form.Label>
                            <Form.Control type="number"  name="image_order" value={this.props.order} onChange={this.props.handleOrderChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-image_image">
                            <Form.Label>Image* (695x900)</Form.Label>
                            <div className="thumbnail-container">
                                {thumbnail}
                            </div>
                            <Form.Control type="file" accept=".jpg, .jpeg" name="image_image" onChange={this.props.handleImageChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <FormButtons handleSave={this.props.handleSave}
                                     handleSaveExit={this.props.handleSaveExit}
                                     showDelete={this.props.showDelete}
                                     handleDelete={this.props.handleDelete}
                        />

                    </Form>
                </Container>
            </div>
        );
    }

}

export default ImageForm;