import React from 'react';
import './AboutUs.css'

class AboutUsHome extends React.Component {

	render() {
		return (
			<div className="broker-page-container">
				<h1 className="home-title">About Us</h1>
				<div className='section-abtus'>
					<p className='aboutus-txt'>Welcome to Repeat for Kids, created by Jo Mowbray and Julian Jackson. With a combined experience of over 30 years in supporting young individuals facing social and emotional challenges, we are deeply committed to making a difference.</p>
					<p className='aboutus-txt'>Our belief is firm: every child deserves a safe haven where they are cherished for their unique selves.</p>
					<p className='aboutus-txt'>At Repeat for Kids, our mission is to ensure that each child who visits feels valued, secure and eager to come back. We look forward to welcoming you.</p>
					<p className='aboutus-txt'>Jo & Jules</p>
				</div>

				<br />


				<h2 className="aboutus-subheading">Private Sensory Play Sessions</h2>

				<div className='section-abtus'>
					<p className='aboutus-txt'>
						Imagine a space where your child, especially if they find busy environments overwhelming, can thrive and you can unwind.
					</p>
					<p className='aboutus-txt'>
						Our sensory 'Rainforest' is just that—a calm, safe haven designed for children up to around age 10. It's equipped with a vast array of resources to support sensory, physical, emotional and educational needs.
					</p>
					<p className='aboutus-txt'>
						Opt for exclusive access or a shared experience with friends or family.
					</p>
					<p className='aboutus-txt'>
						<a href="/private-play-sessions">Click here to check availablitiy</a>
					</p>
				</div>

				<br />



				<h2 className="aboutus-subheading">After School Sensory Play Sessions</h2>
				<h3> (Age 4 - Age 10)</h3>

				<div className='section-abtus'>
					<p className='aboutus-txt'>
					Our sensory rainforest, designed specifically for young children including those with special educational needs (SEN), is a vibrant and interactive environment that offers a stimulating journey through sounds, sights and textures, encouraging exploration and learning in a safe, magical setting. Building on this, our after-school sessions create a safe haven for children, providing an opportunity to foster independence, make friends, and enhance social skills, all within an atmosphere that's both fun and supportive.					</p>
					<p className='aboutus-txt'>
						<a href="/after-school-sessions">Click here to check availablitiy</a>
					</p>
				</div>

				<br />


				{/* <h2 className="aboutus-subheading">Toddler Stay and Play</h2>
				<h3> (Walking - Age 4)</h3>

				<div className='section-abtus'>
					<p className='aboutus-txt'>
						Our sensory rainforest is a vibrant, interactive environment designed specifically for young children, including those with special educational needs (SEN). It offers a stimulating journey through sounds, sights, and textures, encouraging exploration and learning in a safe, magical setting.
					</p>
					<p className='aboutus-txt'>
						<a href="/sessions">Click here to check availablitiy</a>
					</p>
				</div>


				<br /> */}


				<h2 className="aboutus-subheading">Parties</h2>

				<div className='section-abtus'>
					<p className='aboutus-txt'>
						To enqure about parties please message our facebook page by <a href='https://www.facebook.com/RepeaTForKids'>clicking here.</a>
					</p>
				</div>

				<br />


				<h2 className="aboutus-subheading">Visit Us</h2>

				<div className='section-abtus'>
					<p className='aboutus-txt'>
						Unit 15, Hartlepool Workshops
					</p>
					<p className='aboutus-txt'>
						Usworth Road, Hartlepool, TS25 1PD
					</p>

				</div>

				<br />


				<h2 className="aboutus-subheading">Contact Us</h2>

				<div className='section-abtus'>
						<p className='aboutus-txt'>Email: <a href="mailto:repeatforkids@gmail.com">repeatforkids@gmail.com</a></p>
						<p className='aboutus-txt'>Jo: <a href="tel:07745614897">07745614897</a></p>
						<p className='aboutus-txt'>Jules: <a href="tel:07391628279">07391628279</a></p>
						<p className='aboutus-txt'>Facebook: <a href="https://www.facebook.com/RepeaTForKids" target="_blank">Repeat for Kids</a></p>

				</div>

			</div>
		);
	}

}

export default AboutUsHome;



// Repeat for Kids is run by myself (Jo Mowbray) and partner (Julian Jackson).
// Together, we have over 30 years experience working with young people, who experience difficulties, socially and emotionally.
// Repeat Kid

// We are passionate about and believe, that every child should have a place to go to, where they feel safe, comfortable and are valued for being themselves.

// Our goal, is that every child who steps across our door, feels this way and wants to return.
// Additional information:
// Repeat Kid
// Child Only Sessions (Age 4 - Age 10)
// Children can come to relax, make friends, talk, and most importantly, have fun.
// As these sessions are child only, this instills a sense of independence, increasing confidence, which positively impacts on a child's well-being.
// Parent/Carer sessions (From around 18 months - age 10)

// Parent/Carer sessions (From around 18 months - age 10... These sessions are for children who need 1 to 1
// support or may not yet be confident enough to stay o their own.
// Toddler Sessions (From walking to age 4)
