import React, {Component} from 'react'
import Form from "react-bootstrap/Form";

class NameAndAge extends Component {

    handleNameChange = (name) => {
        this.props.handleNameChange(name.target.name, name.target.value)
    }

    handleAgeChange = (age) => {
        this.props.handleAgeChange(age.target.name, age.target.value)
    }

    render() {

        return (
            <div>
                <Form.Group controlId={"form-child_name_" + this.props.num}>
                    <Form.Label>{"Child " + this.props.num + "'s Name"}</Form.Label>
                    <Form.Control type="text"  name={"child_name_" + this.props.num} placeholder="Child's full name" onChange={this.handleNameChange} onBlur={this.props.handleErrorFieldChange}/>
                </Form.Group>
                <Form.Group controlId={"form-child_age_" + this.props.num}>
                    <Form.Label>{"Child " + this.props.num + "'s Age"}</Form.Label>
                    <Form.Control type="number"  name={"child_age_" + this.props.num} placeholder="Child's age" onChange={this.handleAgeChange} onBlur={this.props.handleErrorFieldChange}/>
                </Form.Group>
            </div>
        )

    }

}

export default NameAndAge
