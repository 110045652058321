import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import "../common/form-buttons/FormTemplate.css";
import FormButtons from "../common/form-buttons/FormButtons";
import {setPageContentMargin} from "../../../Utils";
import Notification from "../common/notification/Notification";

class EventForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/events",
            url: "https://api.repeatforkids.co.uk/api/events",
            type_options: ['session', 'event'],
        }
    }

    componentDidMount() {
        setPageContentMargin();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.type !== this.props.type) {
            setPageContentMargin()
        }
    }

    setErrorFields = () => {
        if (this.props.error !== "" && this.props.error_fields.length !== 0) {
            for (let key in this.props.error_fields) {
                let field_id = "form-" + this.props.error_fields[key]
                let field = document.getElementById(field_id)
                if (field !== null) {
                    field.style.borderColor = "red";
                }
            }
            return <Notification type="error" text="Please make sure all required fields are filled" fade={false} form_notification={true}/>
        } else {
            return ""
        }
    }

    render () {

        let error_banner = this.setErrorFields()
        let thumbnail;

        if (this.props.image !== undefined && this.props.image !== "") {
            thumbnail = <img className="img-thumbnail" src={this.props.image} alt="image thumbnail"/>
        } else {
            thumbnail = <p className="no-img">Using default image.</p>
        }

        return(
            <div>
                {error_banner}
                <Container>
                    <Form className="event_form">
                        <Form.Group controlId="form-event_booked">
                            <Form.Control type="hidden" name="event_booked" value={this.props.booked}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_name">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control type="text" name="event_name" placeholder="Session name" value={this.props.name} onChange={this.props.handleNameChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_date">
                            <Form.Label>Date*</Form.Label>
                            <Form.Control type="date"  name="event_date" value={this.props.date} onChange={this.props.handleDateChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_time">
                            <Form.Label>Start*</Form.Label>
                            <Form.Control type="time"  name="event_time" value={this.props.time} onChange={this.props.handleTimeChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_time">
                            <Form.Label>End*</Form.Label>
                            <Form.Control type="time"  name="event_end" value={this.props.end_time} onChange={this.props.handleEndTimeChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_reserved">
                            <Form.Label>Reserved Slots*</Form.Label>
                            <Form.Control type="number"  name="event_reserved" value={this.props.reserved} onChange={this.props.handleReservedChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_max">
                            <Form.Label>Max Slots*</Form.Label>
                            <Form.Control type="number" name="event_max" value={this.props.max} onChange={this.props.handleMaxChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_price">
                            <Form.Label>Price (£)*</Form.Label>
                            <Form.Control type="number" name="event_price" value={this.props.price} onChange={this.props.handlePriceChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-event_image">
                            <Form.Label>Image (695x900)</Form.Label>
                            <div className="thumbnail-container">
                                {thumbnail}
                            </div>
                            <Form.Control type="file" accept=".jpg, .jpeg" name="event_image" onChange={this.props.handleImageChange} onBlur={this.props.handleErrorFieldChange}/>
                        </Form.Group>

                        <FormButtons handleSave={this.props.handleSave}
                                     handleSaveExit={this.props.handleSaveExit}
                                     showDelete={this.props.showDelete}
                                     handleDelete={this.props.handleDelete}
                        />

                    </Form>
                </Container>
            </div>
        );
    }

}

export default EventForm;