import React from 'react';
import './Header.css'
import Logout from "../logout/Logout";
import {NavLink} from "react-router-dom";
import {BsGrid1X2Fill} from 'react-icons/bs'

class Header extends React.Component {

    render() {
        let style = "logo-text logo-text-mobile"
        return (
            <div className="function-bar">
                <div className="logo">
                    <h2 className={style}>Admin</h2>
                </div>
                <div className="action-icons">
                    <NavLink className="to-site-icon" to="/"><BsGrid1X2Fill /></NavLink>
                    <Logout handleLogoutClick={this.props.handleLogoutClick}/>
                </div>
            </div>
        )
    }
}

export default Header;