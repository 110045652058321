export const setPageContentMargin = () => {
    //todo rather than setting the css values try adding different class names (might make the transition smoother)
    let menu_open = document.getElementsByClassName("sidenav---collapsed---LQDEv")[0];
    let page_container = document.getElementsByClassName('page-container')[0];

    if (menu_open === undefined) {
        page_container.style.marginLeft = "240px";
    } else {
        page_container.style.marginLeft = "64px";
    }
}

export const generateDisplayDate = (date) => {
    // convert date from yyyy-mm-dd to dd/mm/yy
    let datePart = date.match(/\d+/g);
    let year = datePart[0].substring(2);
    let month = datePart[1], day = datePart[2];
    return day + '/' + month + '/' + year;
}

export const capitalizeF = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeWords = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

export const formatDate = (dateStr) => {
    let dArr = dateStr.split("-");  // ex input "2010-01-18"
    return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0].substring(2); //ex out: "18/01/10"
}

export const randomString = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
