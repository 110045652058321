import React from 'react';
import './Gallery.css';
import '../common/form-buttons/FormTemplate.css'
import {setPageContentMargin, randomString} from "../../../Utils";
import DataGrid from "../common/data-grid/DataGrid";
import Image from "./Image";
import PageHeader from "../common/page-header/PageHeader";
import Button from "react-bootstrap/Button";
import Notification from "../common/notification/Notification";
import {formatDate} from "../../../Utils";

class GalleryPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// url: "http://localhost/rfk-rest/rfk-api/api/gallery",
			url: "https://api.repeatforkids.co.uk/api/gallery",
			page: 'home',
			loading: true,
			table_data: [],
			image_data: [],
			selected_image: "",
			success: ""
		};
	}

	componentDidMount() {
		setPageContentMargin();
		this.getImages();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		setPageContentMargin();
		if (prevState.selected_image !== this.state.selected_image) {
			if (this.state.selected_image !== "" && this.state.selected_image !==0) {
				this.getImageDetails(this.state.selected_image);
			} else {
				this.getImages();
			}
		}
	}

	getImages = () => {
		let formData = new FormData();
		formData.append('action', 'get-images');
		formData.append('table_view', 1);
		formData.append('recent', (this.props.recent) ? 1 : 0)

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			this.prepareDataForTable(data.data)
		}).catch( (e) => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});
	}

	getImageDetails = (image_id) => {
		let formData = new FormData();
		formData.append('action', 'get-image-details');
		formData.append('image_id', image_id)

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			this.setState({
				page: "image-form",
				table_data: [],
				image_data: data.data,
				success: ""
			})
		}).catch( () => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});
	}

	prepareDataForTable = (gallery_data) => {

		let columns = [
			{ name: "Name", selector: row => row.name, sortable: true, isDate: false },
			{ name: "Order", selector: row => row.order, sortable: true, isDate: false },
		];


		let data = [];
		for (let image_data in gallery_data) {
			data.push({
				id: gallery_data[image_data].image_id,
				name: gallery_data[image_data].image_name,
				order: gallery_data[image_data].image_order,
			});
		}


		this.setState({
			table_data: [columns, data],
			loading: false
		});

	}

	handleNewImage = () => {
		this.setState({
			page: 'new-image',
			selected_image: 0,
			success: ""
		})
	}

	handleRowClick = (row) => {
			this.setState({
				selected_image: row.id,
				success: ""
			});
	}

	handleBackClick = () => {
		this.resetPage()
	}

	handleNewImageSave = (image_id) => {
		this.setState({
			page: 'image-form',
			selected_image: image_id,
			message: "Image saved!"
		})
	}

	handleSaveExit = () => {
		this.setNotification()
		this.resetPage();
	}

	handleDeletedImage = () => {
		this.setNotification()
		this.resetPage();
	}

	handleDeleteAll = () => {
		if (localStorage.getItem('aawt')) {
			let code = randomString(4)
			let input = window.prompt("Enter the confirmation code: " + code);

			if (code === input) {
					let formData = new FormData();
					formData.append('action', 'delete-all');
					formData.append('token', localStorage.getItem('aawt'))

					fetch(this.state.url, {
						method: 'POST',
						headers: new Headers(),
						body: formData
					}).then((response) => {
						if (response.status === 200) {
							return response.json();
						} else {
							throw Error();
						}
					}).then((data) => {
						if (data.statusCode === 200) {
							this.getImages();
						} else {
							//todo handle this error
							throw Error()
						}
					}).catch((err) => {
						console.log(err)
						this.setState({
							error: "Something went wrong. Please try again later."
						});
					});

			}
		} else {
			// alert("handle no auth error");
		}
	}

	resetPage = () => {
		this.setState({
			page: "home",
			selected_image: "",
		})
	}

	setNotification = () => {
		this.setState({
			success: true
		})
	}

	render() {

		let notification;
		if (!this.props.recent) {
			if (this.state.success) {
				notification = <Notification type="success" text="Gallery saved" fade={true} form_notification={false}/>
			} else if (!this.state.success && this.state.success !== "") {
				notification = <Notification type="error" text="Saved failed" fade={true} form_notification={false}/>
			}
		}

		let page_title;
		let page_content;
		let show_back;
		let sub_heading = false
		let styling = "page-container";
		if (this.state.page === "home") {
			sub_heading = (this.props.recent) ? true : sub_heading;
			page_title = (this.props.recent) ? 'Recent Gallery Activity' : 'Gallery';
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			let add_button = (this.props.recent) ? "" : <Button className="new-image-button" variant="primary" onClick={this.handleNewImage}>Add New Image</Button>;
			let del_all_button = (this.props.recent) ? "" : <Button className="del-all-btn" variant="primary" onClick={this.handleDeleteAll}>Delete All Images</Button>;
			page_content = (<div><DataGrid
					columns={this.state.table_data[0]}
					data={this.state.table_data[1]}
					loading={this.state.loading}
					handleRowClick={this.handleRowClick}
			/>{add_button}{del_all_button}</div>);
			show_back = false;
		} else if (this.state.page === "image-form" && this.state.selected_image !== "")  {
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			page_title = "Edit Image"
			page_content = <Image action="edit" image={this.state.image_data} handleSaveNotification={this.setNotification} handleSaveExit={this.handleSaveExit} handleDeletedImage={this.handleDeletedImage}/>
			show_back = true;
		} else if (this.state.page === "new-image" && this.state.selected_image !== "") {
			page_title = "New Image";
			page_content = <Image action="create" handleSaveNotification={this.setNotification} handleNewImageSave={this.handleNewImageSave} handleSaveExit={this.handleSaveExit} />
			show_back = true;
		}

		return(
			<div className={styling}>
				{notification}
				<PageHeader
					sub_heading={sub_heading}
					page_title={page_title}
					show_back={show_back}
					handleBackClick={this.handleBackClick}
				/>
				<div className="page-content">
					{page_content}
				</div>
			</div>
        );
	}

}

export default GalleryPage;