import React from "react";
import BookingForm from "./BookingForm";
import {setPageContentMargin} from "../../../Utils";

class Booking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/orders",
            url: "https://api.repeatforkids.co.uk/api/orders",
            order_id: "",
            order_parent: "",
            order_email: "",
            order_contact: "",
            order_child_name_1: "",
            order_child_age_1: "",
            order_child_name_2: "",
            order_child_age_2: "",
            order_child_name_3: "",
            order_child_age_3: "",
            order_child_name_4: "",
            order_child_age_4: "",
            order_child_name_5: "",
            order_child_num: "",
            order_med_info: "",
            order_add_info: "",
            order_photo_consent: "",
            order_completed: "",
            session_name: "",
            session_time: "",
            session_date: ""
        };
    }

    componentDidMount() {
        setPageContentMargin();
        if (this.props.action === "view") {
            this.setState({
                order_id: this.props.booking[0].order_id,
                order_parent: this.props.booking[0].order_parent,
                order_email: this.props.booking[0].order_email,
                order_contact: this.props.booking[0].order_contact,
                order_child_name_1:  this.props.booking[0].order_child_name_1,
                order_child_age_1:  this.props.booking[0].order_child_age_1,
                order_child_name_2:  this.props.booking[0].order_child_name_2,
                order_child_age_2:  this.props.booking[0].order_child_age_2,
                order_child_name_3:  this.props.booking[0].order_child_name_3,
                order_child_age_3:  this.props.booking[0].order_child_age_3,
                order_child_name_4:  this.props.booking[0].order_child_name_4,
                order_child_age_4:  this.props.booking[0].order_child_age_4,
                order_child_name_5:  this.props.booking[0].order_child_name_5,
                order_child_age_5:  this.props.booking[0].order_child_age_5,
                order_child_num: this.props.booking[0].order_child_num,
                order_med_info: this.props.booking[0].order_med_info,
                order_add_info: this.props.booking[0].order_add_info,
                order_photo_consent: this.props.booking[0].order_photo_consent,
                order_completed: this.props.booking[0].order_completed,
                session_name: this.props.booking[0].session_name,
                session_date: this.props.booking[0].session_date,
                session_time: this.props.booking[0].session_time,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setPageContentMargin();
    }

    handleDelete = (e) => {
        if (localStorage.getItem('aawt')) {
            if (window.confirm("Are you sure you want to delete " + this.state.order_parent + "'s order?.\nThis can not be undone")) {
                let formData = new FormData();
                formData.append('action', 'delete-order');
                formData.append('token', localStorage.getItem('aawt'))
                formData.append('order_id', this.state.order_id);

                fetch(this.state.url, {
                    method: 'POST',
                    headers: new Headers(),
                    body: formData
                }).then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw Error();
                    }
                }).then((data) => {
                    if (data.statusCode === 200) {
                        this.props.handleDeletedOrder();
                    } else {
                        //todo handle this error
                        console.log("error");
                    }
                }).catch(() => {
                    this.setState({
                        error: "Something went wrong. Please try again later."
                    });
                });
            }
        } else {
            // alert("handle no auth error");
        }
    }


    render() {
        return (
            <div className="page-heading-container">
                <h2 className="sub-heading">{this.state.event_name}</h2>
                <BookingForm
                    action={this.props.action}
                    order_id={this.state.order_id}
                    order_parent={this.state.order_parent}
                    order_email={this.state.order_email}
                    order_contact={this.state.order_contact}
                    order_child_name_1={this.state.order_child_name_1}
                    order_child_age_1={this.state.order_child_age_1}
                    order_child_name_2={this.state.order_child_name_2}
                    order_child_age_2={this.state.order_child_age_2}
                    order_child_name_3={this.state.order_child_name_3}
                    order_child_age_3={this.state.order_child_age_3}
                    order_child_name_4={this.state.order_child_name_4}
                    order_child_age_4={this.state.order_child_age_4}
                    order_child_name_5={this.state.order_child_name_5}
                    order_child_age_5={this.state.order_child_age_5}
                    order_child_num={this.state.order_child_num}
                    order_med_info={this.state.order_med_info}
                    order_add_info={this.state.order_add_info}
                    order_photo_consent={this.state.order_photo_consent}
                    order_completed={this.state.order_completed}
                    session_name={this.state.session_name}
                    session_date={this.state.session_date}
                    session_time={this.state.session_time}
                    handleDelete={this.handleDelete}
                />
            </div>
        );
    }

}

export default Booking;
