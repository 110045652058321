import React from 'react';
import {setPageContentMargin} from "../../../Utils";
import PageHeader from "../common/page-header/PageHeader";
import './Dashboard.css';
import {
	BsFillCalendar3EventFill,
	BsFillClockFill,
	BsFillImageFill,
	BsBookFill,
	BsEmojiSmileFill
} from 'react-icons/bs'
import {NavLink} from "react-router-dom";
import EventsPage from "../events/EventsPage";
import SessionsPage from "../sessions/SessionsPage";
import ToddlerSessionsPage from '../toddler-sessions/ToddlerSessionsPage';
import BookingsPage from "../bookings/BookingsPage";
import GalleryPage from "../gallery/GalleryPage";
import { FaChild } from 'react-icons/fa';

class Dashboard extends React.Component {

	componentDidMount() {
		setPageContentMargin();
	}

	render() {
		let page_title = "Hi " + this.props.user + "!";

		return(
			<div className="page-container">
				<PageHeader
					page_title={page_title}
					show_back={false}
				/>
				<div className="page-content dashboard-page-content">

					<div className="action-tiles-container">
						<NavLink to='/admin-bookings'>
							<div className="action-tile">
								<BsBookFill className='tile-icon' />
								<h3>Bookings</h3>
							</div>
						</NavLink>

						<NavLink to='/admin-sessions'>
							<div className="action-tile">
								<BsFillClockFill className='tile-icon' />
								<h3>Sessions</h3>
							</div>
						</NavLink>

						<NavLink to='/admin-private-hires'>
							<div className="action-tile">
								<FaChild className='tile-icon' />
								<h3>Private Hires</h3>
							</div>
						</NavLink>

						<NavLink to='/admin-general-play-sessions'>
							<div className="action-tile">
								<BsFillCalendar3EventFill className='tile-icon' />
								<h3>General Play Sessions</h3>
							</div>
						</NavLink>

						<NavLink to='/admin-reviews'>
							<div className="action-tile">
								<BsEmojiSmileFill className='tile-icon' />
								<h3>Reviews</h3>
							</div>
						</NavLink>

						<NavLink to='/admin-gallery'>
							<div className="action-tile">
								<BsFillImageFill className='tile-icon' />
								<h3>Gallery</h3>
							</div>
						</NavLink>
					</div>

					<div className="recent-section">
						<div className="recent-container">
							<BookingsPage recent={true}/>
						</div>

						<div className="recent-container">
							<SessionsPage recent={true}/>
						</div>

						<div className="recent-container">
							<ToddlerSessionsPage recent={true}/>
						</div>

						<div className="recent-container">
							<EventsPage recent={true}/>
						</div>

						<div className="recent-container">
							<GalleryPage recent={true}/>
						</div>
					</div>

				</div>
			</div>
        );
	}

}

export default Dashboard;