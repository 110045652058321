import {Component} from "react";
import './Paypal.css'
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";

class Paypal extends Component {

    createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: this.props.price
                    }
                }
            ]
        })
    }

    onApprove = (data, actions) => {
        return actions.order.capture().then((details) => {
            this.props.handlePayment(details)
        })

    }

    onError = (err) => {
        console.error(err);
    }


    render() {
        {/* todo the client id needs handling and securing*/}
        let options = {
            "client-id": "AeNpadVnEx6u2YH5zUpMcaufyXyRi7ohPhUANMYtj2x9tCpPD8d_0l481jqb_sOJmmMZ6vFGDDR0lY1f",
            "currency": "GBP"
        }

        return (
            <div className="paypal-checkout">
                <PayPalScriptProvider options={options}>
                    <PayPalButtons createOrder={this.createOrder} onApprove={this.onApprove} onError={this.onError}/>
                </PayPalScriptProvider>
            </div>
        )
    }

}

export default Paypal;
