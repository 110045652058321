import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import "../common/form-buttons/FormTemplate.css";
import FormButtons from "../common/form-buttons/FormButtons";
import {setPageContentMargin} from "../../../Utils";
import Notification from "../common/notification/Notification";
import Button from "react-bootstrap/Button";


class BookingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/orders",
            url: "https://api.repeatforkids.co.uk/api/orders",
        }
    }

    componentDidMount() {
        setPageContentMargin();
    }

    render () {
        return(
            <div>
                <Container>
                    <Form className="booking_form">

                        <Form.Group controlId="form-booking_parent_name">
                            <Form.Label>Parent</Form.Label>
                            <Form.Control type="text" name="parent_name" value={this.props.order_parent} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_parent_email">
                            <Form.Label>Parent Email</Form.Label>
                            <Form.Control type="text" name="parent_email" value={this.props.order_email} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_parent_contact">
                            <Form.Label>Parent Number</Form.Label>
                            <Form.Control type="text" name="parent_contact" value={this.props.order_contact} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_session_name">
                            <Form.Label>Session Name</Form.Label>
                            <Form.Control type="text" name="session_name" value={this.props.session_name} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_session_date">
                            <Form.Label>Session Date</Form.Label>
                            <Form.Control type="text" name="session_date" value={this.props.session_date} readOnly/>
                        </Form.Group>
                        <Form.Group controlId="form-booking_session_time">
                            <Form.Label>Session Time</Form.Label>
                            <Form.Control type="text" name="session_time" value={this.props.session_time} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_num">
                            <Form.Label>Number of children</Form.Label>
                            <Form.Control type="text" name="child_num" value={this.props.order_child_num} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_name_1">
                            <Form.Label>Child Name 1</Form.Label>
                            <Form.Control type="text" name="child_name_1" value={this.props.order_child_name_1} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_age_1">
                            <Form.Label>Child Age 1</Form.Label>
                            <Form.Control type="text" name="child_age_1" value={this.props.order_child_age_1} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_name_2">
                            <Form.Label>Child Name 2</Form.Label>
                            <Form.Control type="text" name="child_name_2" value={this.props.order_child_name_2} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_age_2">
                            <Form.Label>Child Age 2</Form.Label>
                            <Form.Control type="text" name="child_age_2" value={this.props.order_child_age_2} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_name_3">
                            <Form.Label>Child Name 3</Form.Label>
                            <Form.Control type="text" name="child_name_3" value={this.props.order_child_name_3} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_age_3">
                            <Form.Label>Child Age 3</Form.Label>
                            <Form.Control type="text" name="child_age_3" value={this.props.order_child_age_3} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_name_4">
                            <Form.Label>Child Name 4</Form.Label>
                            <Form.Control type="text" name="child_name_4" value={this.props.order_child_name_4} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_age_4">
                            <Form.Label>Child Age 4</Form.Label>
                            <Form.Control type="text" name="child_age_4" value={this.props.order_child_age_4} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_name_5">
                            <Form.Label>Child Name 5</Form.Label>
                            <Form.Control type="text" name="child_name_5" value={this.props.order_child_name_5} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_child_age_5">
                            <Form.Label>Child Age 5</Form.Label>
                            <Form.Control type="text" name="child_age_5" value={this.props.order_child_age_5} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_med_info">
                            <Form.Label>Medical/Allergy Information</Form.Label>
                            <Form.Control as="textarea" rows={5} name="med_info" value={this.props.order_med_info} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_add_info">
                            <Form.Label>Additional Information</Form.Label>
                            <Form.Control as="textarea" rows={5} name="add_info" value={this.props.order_add_info} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_photo_consent">
                            <Form.Label>Photo Consent</Form.Label>
                            <Form.Control type="text" name="photo_consent" value={this.props.order_photo_consent} readOnly/>
                        </Form.Group>

                        <Form.Group controlId="form-booking_completed">
                            <Form.Label>Booking Placed</Form.Label>
                            <Form.Control type="text" name="booking_completed" value={this.props.order_completed} readOnly/>
                        </Form.Group>

                        <Button className="del-all-btn booking-del" variant="primary" onClick={this.props.handleDelete}>Delete Order</Button>

                    </Form>
                </Container>
            </div>
        );
    }

}

export default BookingForm;