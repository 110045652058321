import React from 'react';
import './CancellationPolicy.css'

class CancellationPolicy extends React.Component {

	render() {
		return (
			<div className="broker-page-container">
				<h1 className="home-title">Cancellation Policy</h1>
				{/* <div className='section-abtus'>
					<p className='aboutus-txt'>Welcome to Repeat for Kids, founded by Jo Mowbray and Julian Jackson. With a combined experience of over 30 years in supporting young individuals facing social and emotional challenges, we are deeply committed to making a difference.</p>
					<p className='aboutus-txt'>Our belief is firm: every child deserves a safe haven where they are cherished for their unique selves.</p>
					<p className='aboutus-txt'>At Repeat for Kids, our mission is to ensure that each child who visits feels valued, secure, and eager to come back. We look forward to welcoming you.</p>
					<p className='aboutus-txt'>Jo & Jules</p>
				</div>

				<br /> */}

				<div className='section-abtus'>
					<p className='aboutus-txt'>
					All sessions are non-refundable and non-transferable.
					</p>
				</div>

				<br />


			</div>
		);
	}

}

export default CancellationPolicy;



// Repeat for Kids is run by myself (Jo Mowbray) and partner (Julian Jackson).
// Together, we have over 30 years experience working with young people, who experience difficulties, socially and emotionally.
// Repeat Kid

// We are passionate about and believe, that every child should have a place to go to, where they feel safe, comfortable and are valued for being themselves.

// Our goal, is that every child who steps across our door, feels this way and wants to return.
// Additional information:
// Repeat Kid
// Child Only Sessions (Age 4 - Age 10)
// Children can come to relax, make friends, talk, and most importantly, have fun.
// As these sessions are child only, this instills a sense of independence, increasing confidence, which positively impacts on a child's well-being.
// Parent/Carer sessions (From around 18 months - age 10)

// Parent/Carer sessions (From around 18 months - age 10... These sessions are for children who need 1 to 1
// support or may not yet be confident enough to stay o their own.
// Toddler Sessions (From walking to age 4)
