import React, {Component} from 'react';
import './Dataform.css'
import SessionCard from "../../common/cards/session-card/SessionCard";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Buttons from "../../common/button/Buttons";
import Toggle from "../../common/toggle/Toggle";
import NameAndAge from "./NameAndAge";
import Notification from "../../../../admin/components/common/notification/Notification";


class DataForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            child_num: 1,
            child_name_1: "",
            child_age_1: "",
            child_name_2: "",
            child_age_2: "",
            child_name_3: "",
            child_age_3: "",
            child_name_4: "",
            child_age_4: "",
            child_name_5: "",
            child_age_5: "",
            child_med_info: "",
            child_add_info: "",
            child_parent_name: "",
            child_parent_email: "",
            child_parent_number: "",
            child_photo_consent: "",
            // child_one_2_one: "",
            // child_only: "",
            error: "",
            allowed_empty: ['child_med_info', 'child_add_info', 'child_name_5', 'child_age_5', 'child_name_4', 'child_age_4', 'child_name_3', 'child_age_3', 'child_name_2', 'child_age_2'],
            error_fields: []
        }
    }

    // todo dont save any of the data here until after the payment has been made
    // todo if user enters say 2 children then submits only one remove the second from the total and dont save to db

    handleChildNumChange = (child_num) => {
        let empty_allowed = ['child_med_info', 'child_add_info', 'child_name_5', 'child_age_5', 'child_name_4', 'child_age_4', 'child_name_3', 'child_age_3', 'child_name_2', 'child_age_2']
        for (let i=0; i < child_num.target.value; i++) {
            if (i !== 0) {
                empty_allowed.pop()
                empty_allowed.pop()
            }
        }

        this.setState({
            child_num: child_num.target.value,
            allowed_empty: empty_allowed
        })
    }

    handleNameChange = (child_num, name) => {
        this.setState({[child_num]: name})
    }

    handleAgeChange = (child_num, age) => {
        this.setState({[child_num]: age
        })
    }

    handleMedInfoChange = (med_info) => {
        this.setState({
            child_med_info: med_info.target.value
        })
    }

    handleAddInfoChange = (add_info) => {
        this.setState({
            child_add_info: add_info.target.value
        })
    }

    handleParentNameChange = (parent_name) => {
        this.setState({
            child_parent_name: parent_name.target.value
        })
    }

    handleEmailChange = (email) => {
        this.setState({
            child_parent_email: email.target.value
        })
    }

    handleNumberChange = (number) => {
        this.setState({
            child_parent_number: number.target.value
        })
    }

    handlePhotoConsentChange = (option) => {
        this.setState({child_photo_consent: option})
    }

    // handleOne2OneChange = (option) => {
    //     this.setState({child_one_2_one: option})
    // }

    // handleChildOnlyChange = (option) => {
    //     this.setState({child_only: option})
    // }

    checkFormValues = () => {
        let form_error = false
        let error_fields = []
        for (let key in this.state) {
            if (key.includes('child_')) {
                if (this.state[key] === "" && !this.state.allowed_empty.includes(key)) {
                    error_fields.push(key)
                    form_error = true
                    window.scrollTo(0, 0)
                } else {
                    error_fields.filter((field) => {
                        return field !== key
                    });
                }
            }
        }

        if (form_error && error_fields.length !== 0) {
            this.setState({
                error: "Please make sure all required fields are filled.",
                error_fields: error_fields
            })
        }

        return form_error
    }

    setErrorFields = () => {
        if (this.state.error !== "" && this.state.error_fields.length !== 0) {
            for (let key in this.state.error_fields) {
                let field_id = "form-" + this.state.error_fields[key]
                let field = document.getElementById(field_id)
                if (field !== null) {
                    field.style.border = "2px solid red";
                }
            }
            return <Notification type="error" text="Please make sure all fields are filled" fade={false} form_notification={true}/>
        } else {
            return ""
        }
    }

    handleErrorFieldChange = (field) => {
        if (this.state.error !== "" && this.state.error_fields.length !== 0) {
            if (field.target.value !== "") {
                field.target.style.border = "1px solid white";
                this.setState({
                    error_fields: this.state.error_fields.filter((error_field) => {
                        return error_field !== field.target.id.replace('form-', '')
                    })
                })
            }
        }
    }

    handleProceed = () => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            let data = {
                session_data: this.props.data,
                child_num: this.state.child_num,
                name_1: this.state.child_name_1,
                age_1: this.state.child_age_1,
                name_2: this.state.child_name_2,
                age_2: this.state.child_age_2,
                name_3: this.state.child_name_3,
                age_3: this.state.child_age_3,
                name_4: this.state.child_name_4,
                age_4: this.state.child_age_4,
                name_5: this.state.child_name_5,
                age_5: this.state.child_age_5,
                med_info: this.state.child_med_info,
                add_info: this.state.child_add_info,
                parent_name: this.state.child_parent_name,
                email: this.state.child_parent_email,
                num: this.state.child_parent_number,
                consent: this.state.child_photo_consent
            }
            this.props.handleProceed(data)
        }
    }

    generateChildNumberOptions = () => {
        let num_children_options = []
        for (let i=0; i < this.props.data.session_slots; i++) {
            if (i < 5) { // max of 5 children per booking
                num_children_options.push(
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                )
            }
        }

        return num_children_options
    }

    handleNameAgeFieldsCalc = () => {
        let num_of_children = ""
        for (let i=0; i < this.state.child_num; i++) {
            num_of_children += ((i+1) == this.state.child_num ) ? (i + 1) : (i + 1) + ","
        }
        return num_of_children.split(",")
    }

    render() {

        let num_children_options = this.generateChildNumberOptions()
        let num_of_children = this.handleNameAgeFieldsCalc()
        let handleProceed = this.handleProceed
        let error_banner = this.setErrorFields()
        let btn_disabled = false
        // let one2one_error
        // let child_only_error;

        // if (this.state.child_one_2_one === "Yes") {
        //     handleProceed = undefined
        //     btn_disabled = true
        //     one2one_error = <p className="form-notification">We are very sorry but we are currently unable to provide one-to-one support. For more information feel free to contact us</p>
        // }

        // if (this.state.child_only === "No") {
        //     handleProceed = undefined
        //     btn_disabled = true
        //     child_only_error = <p className="form-notification">Sorry, these sessions are child only.</p>
        // }

        return (
            <div>
                <div>
                    <SessionCard data={this.props.data} display_only={true}/>
                </div>

                <Container className="data-form-app">
                    <p className="form-notice">We just need a few details...</p>
                    {error_banner}

                    <Form className="session_form">
                        <Form.Group controlId="form-child_num">
                            <Form.Label>No. of Children</Form.Label>
                            <Form.Select name="child_num" onChange={this.handleChildNumChange} onBlur={this.handleErrorFieldChange}>
                                {num_children_options}
                            </Form.Select>
                        </Form.Group>

                        {num_of_children.map((num) => (
                            <NameAndAge
                                key={num.replace('/,/g', "")}
                                num={num.replace('/,/g', "")}
                                handleNameChange={this.handleNameChange}
                                handleAgeChange={this.handleAgeChange}
                                handleErrorFieldChange={this.handleErrorFieldChange}
                            />
                        ))}

                        <Form.Group controlId="form-child_med_info">
                            <Form.Label>Medical/Allergy Info:</Form.Label>
                            <Form.Control as="textarea" rows={3} name="med-info" placeholder="Any medical or allergy info you think we should be aware of" onChange={this.handleMedInfoChange} onBlur={this.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-child_add_info">
                            <Form.Label>Additional Info:</Form.Label>
                            <Form.Control as="textarea" rows={3} name="add-info" placeholder="Any additional info you think we should be aware of" onChange={this.handleAddInfoChange} onBlur={this.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-child_parent_name">
                            <Form.Label>Parent/Carer Name</Form.Label>
                            <Form.Control type="text" name="parent-name" placeholder="Parent/Carer's full name" onChange={this.handleParentNameChange} onBlur={this.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-child_parent_email">
                            <Form.Label>Parent/Carer Email</Form.Label>
                            <Form.Control type="text" name="parent-email" placeholder="Parent/Carer's email" onChange={this.handleEmailChange} onBlur={this.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group controlId="form-child_parent_number">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control type="number" name="parent-number" placeholder="Parent/Carer's contact number" onChange={this.handleNumberChange} onBlur={this.handleErrorFieldChange}/>
                        </Form.Group>

                        <Form.Group className="form-child_photo_consent" controlId="form-consent">
                            <Form.Label>Photo Consent</Form.Label>
                            <Toggle handleChange={this.handlePhotoConsentChange} />
                        </Form.Group>

                        {/* <Form.Group className="form-child_one_2_one" controlId="form-one2one">
                            <Form.Label>Does your child require one-to-one support?</Form.Label>
                            <Toggle handleChange={this.handleOne2OneChange} />
                            {one2one_error}
                        </Form.Group>

                        <Form.Group className="form-child_one_2_one" controlId="form-one2one">
                            <Form.Label>I understand these are child only sessions.</Form.Label>
                            <Toggle handleChange={this.handleChildOnlyChange} />
                            {child_only_error}
                        </Form.Group> */}

                    </Form>
                    </Container>

                <div className="data-form-button" onClick={handleProceed}>
                    <Buttons btn_text="Proceed to Payment" link={false} disabled={btn_disabled}/>
                </div>
            </div>
        )
    }

}

export default DataForm;