import React from 'react';
import './Home.css'
import Buttons from "../common/button/Buttons";

class Home extends React.Component {

	render() {
		return (
			<div className="home-page-container">
				<h1 className="home-title">Repeat For Kids</h1>
				<h2 className="home-subheading">Hi, we are Repeat For Kids, join us to explore and experience our little piece of calm</h2>
				<div className="home-buttons">
					<Buttons to="/after-school-sessions" btn_text="Book an After-School Session" link={true}/>
					<Buttons to="/private-play-sessions" btn_text="Book a Private Play Session" link={true}/>
					<Buttons to="/general-play-sessions" btn_text="Book a General Play Session" link={true}/>
				</div>
			</div>
		);
	}
}

export default Home;