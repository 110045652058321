import React from 'react';
import './Events.css';
import '../common/form-buttons/FormTemplate.css'
import {setPageContentMargin, randomString} from "../../../Utils";
import DataGrid from "../common/data-grid/DataGrid";
import Event from "./Event";
import PageHeader from "../common/page-header/PageHeader";
import Button from "react-bootstrap/Button";
import Notification from "../common/notification/Notification";
import {formatDate} from "../../../Utils";

class EventsPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// url: "http://localhost/rfk-rest/rfk-api/api/events",
			url: "https://api.repeatforkids.co.uk/api/events",
			page: 'home',
			loading: true,
			table_data: [],
			event_data: [],
			selected_event: "",
			success: ""
		};
	}

	componentDidMount() {
		setPageContentMargin();
		this.getEvents();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		setPageContentMargin();
		if (prevState.selected_event !== this.state.selected_event) {
			if (this.state.selected_event !== "" && this.state.selected_event !==0) {
				this.getEventDetails(this.state.selected_event);
			} else {
				this.getEvents();
			}
		}
	}

	getEvents = () => {
		let formData = new FormData();
		formData.append('action', 'get-events');
		formData.append('table_view', 1);
		formData.append('recent', (this.props.recent) ? 1 : 0)

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			this.prepareDataForTable(data.data)
		}).catch( (e) => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});
	}

	getEventDetails = (event_id) => {
		let formData = new FormData();
		formData.append('action', 'get-event-details');
		formData.append('event_id', event_id)

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			this.setState({
				page: "event-form",
				table_data: [],
				event_data: data.data,
				success: ""
			})
		}).catch( () => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});
	}

	prepareDataForTable = (events_data) => {

		let columns = [
			{ name: "Name", selector: row => row.name, sortable: true, isDate: false },
			{ name: "Date", selector: row => row.date, sortable: true, isDate: true },
			{ name: "Time", selector: row => row.time, sortable: true, isDate: false },
			{ name: "Booked", selector: row => row.booked, sortable: false, isDate: true },
			{ name: "Reserved", selector: row => row.reserved, sortable: false, isDate: false },
			{ name: "Max", selector: row => row.max, sortable: false, isDate: false },
		];


		let data = [];
		for (let event_data in events_data) {
			data.push({
				id: events_data[event_data].session_id,
				name: events_data[event_data].session_name,
				date: formatDate(events_data[event_data].session_date),
				time: events_data[event_data].session_time,
				reserved: events_data[event_data].session_reserved,
				booked: events_data[event_data].session_booked,
				max: events_data[event_data].session_max
			});
		}


		this.setState({
			table_data: [columns, data],
			loading: false
		});

	}

	handleNewEvent = () => {
		this.setState({
			page: 'new-event',
			selected_event: 0,
			success: ""
		})
	}

	handleRowClick = (row) => {
			this.setState({
				selected_event: row.id,
				success: ""
			});
	}

	handleBackClick = () => {
		this.resetPage()
	}

	handleNewEventSave = (event_id) => {
		this.setState({
			page: 'event-form',
			selected_event: event_id,
			message: "General Play Session saved!"
		})
	}

	handleSaveExit = () => {
		this.setNotification()
		this.resetPage();
	}

	handleDeletedEvent = () => {
		this.setNotification()
		this.resetPage();
	}

	handleDeleteAll = () => {
		if (localStorage.getItem('aawt')) {
			let code = randomString(4)
			let input = window.prompt("Enter the confirmation code: " + code);

			if (code === input) {
					let formData = new FormData();
					formData.append('action', 'delete-all');
					formData.append('token', localStorage.getItem('aawt'))

					fetch(this.state.url, {
						method: 'POST',
						headers: new Headers(),
						body: formData
					}).then((response) => {
						if (response.status === 200) {
							return response.json();
						} else {
							throw Error();
						}
					}).then((data) => {
						if (data.statusCode === 200) {
							this.getEvents();
						} else {
							//todo handle this error
							throw Error()
						}
					}).catch((err) => {
						console.log(err)
						this.setState({
							error: "Something went wrong. Please try again later."
						});
					});

			}
		} else {
			// alert("handle no auth error");
		}
	}

	resetPage = () => {
		this.setState({
			page: "home",
			selected_event: "",
		})
	}

	setNotification = () => {
		this.setState({
			success: true
		})
	}

	render() {

		let notification;
		if (!this.props.recent) {
			if (this.state.success) {
				notification = <Notification type="success" text="General Play Session saved" fade={true} form_notification={false}/>
			} else if (!this.state.success && this.state.success !== "") {
				notification = <Notification type="error" text="Saved failed" fade={true} form_notification={false}/>
			}
		}

		let page_title;
		let page_content;
		let show_back;
		let sub_heading = false
		let styling = "page-container";
		if (this.state.page === "home") {
			sub_heading = (this.props.recent) ? true : sub_heading;
			page_title = (this.props.recent) ? 'Recent General Play Session Activity' : 'General Play Sessions';
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			let add_button = (this.props.recent) ? "" : <Button className="new-event-button" variant="primary" onClick={this.handleNewEvent}>Add New General Play Session</Button>;
			let del_all_button = (this.props.recent) ? "" : <Button className="del-all-btn" variant="primary" onClick={this.handleDeleteAll}>Delete All General Play Sessions</Button>;
			page_content = (<div><DataGrid
					columns={this.state.table_data[0]}
					data={this.state.table_data[1]}
					loading={this.state.loading}
					handleRowClick={this.handleRowClick}
			/>{add_button}{del_all_button}</div>);
			show_back = false;
		} else if (this.state.page === "event-form" && this.state.selected_event !== "")  {
			styling = (this.props.recent) ? 'recent-page-content' : styling;
			page_title = "Edit General Play Session"
			page_content = <Event action="edit" event={this.state.event_data} handleSaveNotification={this.setNotification} handleSaveExit={this.handleSaveExit} handleDeletedEvent={this.handleDeletedEvent}/>
			show_back = true;
		} else if (this.state.page === "new-event" && this.state.selected_event !== "") {
			page_title = "New General Play Session";
			page_content = <Event action="create" handleSaveNotification={this.setNotification} handleNewEventSave={this.handleNewEventSave} handleSaveExit={this.handleSaveExit} />
			show_back = true;
		}

		return(
			<div className={styling}>
				{notification}
				<PageHeader
					sub_heading={sub_heading}
					page_title={page_title}
					show_back={show_back}
					handleBackClick={this.handleBackClick}
				/>
				<div className="page-content">
					{page_content}
				</div>
			</div>
        );
	}

}

export default EventsPage;