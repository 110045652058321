import React, {Component} from 'react';
import img from '../../../../assets/static/imgs/session_img.jpg'
import './SessionCard.css'
import {formatDate} from "../../../../../Utils";

class SessionCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card_id: this.props.data.session_id,
            card_title: this.props.data.session_name,
            card_spaces: this.props.data.session_slots, //this needs updating
            card_date: formatDate(this.props.data.session_date),
            card_time: this.props.data.session_time,
            card_end_time: this.props.data.session_end_time,
            card_price: this.props.data.session_price,
            card_img: this.props.data.session_image,
        }
    }

    handleClick = () => {
        this.props.handleClick(this.state.card_id)
    }

    calcSpaces = () => {
        let spaces
        let slot_display
        let card_colour = "white"
        let handleClick

        if (this.state.card_spaces > 0) {
            spaces = this.state.card_spaces
            slot_display = "spaces-available"
            handleClick = (this.props.handleClick !== undefined) ? this.handleClick : undefined
        } else {
            spaces = 0
            slot_display = "spaces-unavailable"
            card_colour = "grey"
        }

        return {spaces: spaces, slot_display: slot_display, card_colour: card_colour, handleClick: handleClick}
    }

    checkDisplayOnly = () => {
        return (this.props.display_only) ? " display-only-card" : ""
    }

    generateCardImg = () => {
        return (this.state.card_img === undefined) ? img : this.state.card_img
    }

    render() {
        let spaces_data = this.calcSpaces()
        let card_img = this.generateCardImg()
        let display_only = this.checkDisplayOnly()

        return (
            <div onClick={spaces_data['handleClick']} key={this.state.card_id} className={"card option-card " + spaces_data['card_colour'] + display_only}>
                <div className="card-image-container">
                    <img className="card-image" src={card_img} alt="Poster" />
                </div>
                <div className="card-body">
                    <div className="data-container">
                        <h3 className="card-title">{this.state.card_title}</h3>
                    </div>

                    <div className="data-container">
                        <div className="booking-status"><span className={spaces_data['slot_display']}>- {spaces_data['spaces']} Spaces -</span></div>
                    </div>

                    <div className="data-container">
                        <p className="card-lbl">Date:</p>
                        <p className="card-data-text">{this.state.card_date}</p>
                    </div>

                    <div className="data-container">
                        <p className="card-lbl">Time:</p>
                        <p className="card-data-text">{this.state.card_time} - {this.state.card_end_time}</p>
                    </div>

                    <div className="data-container final-container">
                        <p className="card-lbl card-price">£{this.state.card_price}</p>
                    </div>
                </div>
            </div>
        )
    }

}

export default SessionCard;
