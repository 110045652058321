import React from 'react';
import './Broker.css'
import SessionCard from '../common/cards/session-card/SessionCard'
import {capitalizeF, capitalizeWords} from '../../../Utils'
import DataForm from "./data-form/DataForm";
import {BsArrowLeft} from "react-icons/bs";
import home from "../home/Home";
import Checkout from "./checkout/Checkout";
import ToddlerDataForm from './data-form/ToddlerDataForm';
import CircularProgress from '@mui/material/CircularProgress';

class BrokerHome extends React.Component {

	constructor(props) {
		super(props);
		const type = this.props.type.replaceAll("-", " ");

		let url = "";
		let action = "";
		if (this.props.type === "general-play-sessions") {
			// url = "http://localhost/rfk-rest/rfk-api/api/events";
			url = "https://api.repeatforkids.co.uk/api/events";
			action = "get-events"
		} else if (this.props.type === "after-school-sessions") {
			// url = "http://localhost/rfk-rest/rfk-api/api/sessions"
			url = "https://api.repeatforkids.co.uk/api/sessions";
			action = "get-sessions"
		} else {
			// url = "http://localhost/rfk-rest/rfk-api/api/toddler-sessions"
			url = "https://api.repeatforkids.co.uk/api/toddler-sessions"
			action = "get-toddler-sessions"
		}

		this.state = {
			url: url,
			urlAction: action,
			type: type,
			page: "home",
			chosen_session: [],
			data: [],
			booking_data: [],
			loading: true
		}
	}

	componentDidMount() {
		this.getSessions()
	}

	getSessions = () => {
		let formData = new FormData();
		formData.append('action', this.state.urlAction);

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			if (data.statusCode === 200 || 204) { this.setState({data: data.data, page: "home", loading: false}) } else { throw Error() }
		}).catch( (e) => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});

	}

	handleClick = (session_id) => {
		// loop through all events and get the row that has this id
		let index;
		for (let i=0; i < this.state.data.length; i++) {
			if (this.state.data[i]['session_id'] === session_id) {
				index = i
			}
		}
		this.setState({
			chosen_session: this.state.data[index],
			page: "booking"
		});
	}

	handleBackClick = () => {
		if (this.state.page === "booking") {
			this.setState({chosen_session: [], page: 'home'})
		} else if (this.state.page === "checkout") {
			this.setState({page: 'booking'})
		}
	}

	handleProceedClick = (data) => {
		this.setState({
			page: "checkout",
			booking_data: data
		})
	}

	prepareHomePage = () => {
		let title = capitalizeWords(this.state.type)
		let content
		let form_style = "broker-page-container ";
		let page_sub_header = "page-sub-header"
		let broker_style
		if (!this.state.loading) {
			console.log(this.state)
			if (this.state.data !== undefined && this.state.data.length !== 0) {
					broker_style = "broker-container-grid"
					content = this.state.data.map((data) => (
						<SessionCard key={data.session_id} data={data} handleClick={this.handleClick} />))
				} else {
					content = (
						<div className="no-options-container">
							<h2>Sorry there are no {this.state.type} available at the moment.</h2>
						</div>
					)
				}
			} else {
				content = <CircularProgress size={80} sx={{margin: "20px 0"}} color="inherit" />
			}

		return {form_style: form_style, page_sub_header: page_sub_header, title: title, broker_style: broker_style, content: content}
	}

	prepareBookingPage = () => {
		let title = "You are currently booking"
		let form_style = "broker-page-container form-container";
		let page_sub_header = "page-sub-header"
		let content = this.state.type !== "toddler sessions" 
			? <DataForm data={this.state.chosen_session} handleProceed={this.handleProceedClick}/>
			: <ToddlerDataForm data={this.state.chosen_session} handleProceed={this.handleProceedClick} />;
		let back_arrow = (<div className="back-arrow-app-container" onClick={this.handleBackClick}><BsArrowLeft className="back-arrow-app"/><p className="back-arrow-app-text">Back to {capitalizeF(this.state.type)}</p></div>)

		return {form_style: form_style, page_sub_header: page_sub_header, title: title, content: content, back_arrow: back_arrow}
	}

	prepareCheckoutPage = () => {
		let title = ""
		let form_style = "broker-page-checkout-container";
		let page_sub_header = "page-sub-header checkout-sub-header"
		let content = <Checkout data={this.state.booking_data}/>;
		let back_arrow = (<div className="back-arrow-app-container" onClick={this.handleBackClick}><BsArrowLeft className="back-arrow-app"/><p className="back-arrow-app-text">Back to Booking</p></div>)

		return {form_style: form_style, page_sub_header: page_sub_header, title: title, content: content, back_arrow: back_arrow}
	}

	generatePageData = () => {
		if (this.state.page === "home") {
			return this.prepareHomePage()

		} else if (this.state.page === "booking") {
			return this.prepareBookingPage()

		} else if (this.state.page === "checkout") {
			return this.prepareCheckoutPage()
		}
	}

	render() {

		let page_data = this.generatePageData()

		return (
			<div className={page_data['form_style']} >
				<div className={page_data['page_sub_header']}>
					{page_data['back_arrow']}
					<h1 className="broker-page-title">{page_data['title']}</h1>
				</div>
				<div className={page_data['broker_style']}>
					{page_data['content']}
				</div>
			</div>
		);
	}

}

export default BrokerHome;