import React, {Component} from "react";
import './DataCard.css'
import child_img from "../../../../assets/static/imgs/child-card-icon.png";
import parent_img from "../../../../assets/static/imgs/parent-card-icon.png";
import add_img from "../../../../assets/static/imgs/add-card-icon.png";
import med_img from "../../../../assets/static/imgs/med-card-icon.png";
import cam_img from "../../../../assets/static/imgs/camera-card-icon.png";
import price_img from "../../../../assets/static/imgs/pound-card-icon.png";

class DataCard extends Component {


    generateChildDataCard = () => {
        return (
            <div className="data-card-body-child">
                <div className="data-container-data">
                    <p className="card-name"><strong>Name:</strong></p>
                    <p className="card-name-content">{this.props.data['name']}</p>

                </div>
                <div className="data-container-data">
                    <p className="card-age"><strong>Age:</strong></p>
                    <p className="card-age-content">{this.props.data['age']}</p>
                </div>
            </div>
        )
    }

    generateParentDataCard = () => {
        return (
            <div className="data-card-body-parent">
                <div className="data-container-data">
                    <p className="card-name"><strong>Name:</strong></p>
                    <p className="card-parent-name-content">{this.props.data['name']}</p>
                </div>
                <div className="data-container-data">
                    <p className="card-email"><strong>Email:</strong></p>
                    <p className="card-parent-num-content">{this.props.data['email']}</p>

                </div>
                <div className="data-container-data">
                    <p className="card-number"><strong>Number:</strong></p>
                    <p className="card-parent-num-content">{this.props.data['num']}</p>
                </div>
            </div>
        )
    }

    generateMedDataCard = () => {
        return (
            <div className="data-card-body-parent med-container">
                <div className="data-container-data">
                    <p className="card-name">{(this.props.data !== "") ? this.props.data : 'None'}</p>
                </div>
            </div>
        )
    }

    generateAddDataCard = () => {
        return (
            <div className="data-card-body-parent add-container">
                <div className="data-container-data">
                    <p className="card-name">{(this.props.data !== "") ? this.props.data : 'None'}</p>
                </div>
            </div>
        )
    }

    generatePhotoDataCard = () => {
        return (
            <div className="data-card-body-parent photo-consent-container">
                <div className="data-container-data">
                    <p className="card-photo-content">{this.props.data}</p>
                </div>
            </div>
        )
    }

    generatePriceDataCard = () => {
        return (
            <div className="data-card-body-parent price-container">
                <div className="data-container-data">
                    <p className="data-card-price">To Pay: £{this.props.data}</p>
                </div>
            </div>
        )
    }

    generateCard = () => {
        if (this.props.type === "child-data") {
            return [this.generateChildDataCard(), child_img]

        } else if (this.props.type === "parent-data") {
            return [this.generateParentDataCard(), parent_img]

        } else if (this.props.type === "med-data") {
            return [this.generateMedDataCard(), med_img]

        } else if (this.props.type === "add-data") {
            return [this.generateAddDataCard(), add_img]

        } else if (this.props.type === "photo-data") {
            return [this.generatePhotoDataCard(), cam_img]

        }else if (this.props.type === "price-data") {
            return [this.generatePriceDataCard(), price_img]

        }
    }

    render() {
        let card_data = this.generateCard()
        let card_body = card_data[0]
        let card_img = card_data[1]

        let card_container_style = "card data-card white display_only"
        card_container_style = (this.props.type === "price-data") ? card_container_style += " price-card" : card_container_style

        return (
            <div className={card_container_style}>
                <div className="data-card-img-container">
                    <img className="child-card-image" src={card_img} alt="Poster" />
                </div>

                {card_body}

            </div>
        )
    }

}

export default DataCard;