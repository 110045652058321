import React from "react";
import ImageForm from "./ImageForm";
import {setPageContentMargin} from "../../../Utils";

class Image extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: "http://localhost/rfk-rest/rfk-api/api/gallery",
            url: "https://api.repeatforkids.co.uk/api/gallery",
            image_id: "",
            image_name: "",
            image_date: "",
            image_order: "",
            image_image: "",
            image_filename: "",
            allowed_empty: ['image_date', 'image_filename'],
            error: "",
            error_fields: [],
        };
    }

    componentDidMount() {
        setPageContentMargin();
        if (this.props.action === "edit") {
            this.setState({
                image_id: this.props.image[0].image_id,
                image_name: this.props.image[0].image_name,
                image_date: this.props.image[0].image_date,
                image_order: this.props.image[0].image_order,
                image_image: this.props.image[0].image_image,
                image_filename: this.props.image[0].image_filename,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setPageContentMargin();
    }

    handleImageNameChange = (name) => {
        this.setState({image_name: name.target.value})
    }

    handleImageImageChange = (image) => {
        let files = image.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (img) => {
            this.setState({
                image_image: img.target.result,
            })
        }
    }

    handleImageOrderChange = (order) => {
        this.setState({image_order: order.target.value})
    }

    prepareFormData = () => {
        let name = this.state.image_name;
        let date = this.state.image_date;
        let order = this.state.image_order;
        let image = this.state.image_image;
        let filename = this.state.image_filename;

        let data = {
            'name': name,
            'date': date,
            'order': order,
            'image': image,
            'filename': filename,
        }

        return data;
    }

    doSave = (create, saveExit) => {
        if (localStorage.getItem('aawt')) {
            let data = this.prepareFormData()
            let formData = new FormData();

            formData.append('action', (create) ? "new-image" : "save-image");
            formData.append('token', localStorage.getItem('aawt'));
            formData.append('image_id', this.state.image_id)
            formData.append('image_name', data.name)
            formData.append('image_date', data.date)
            formData.append('image_order', data.order)
            formData.append('image_image', data.image)
            formData.append('image_filename', data.filename)

            fetch(this.state.url, {
                method: 'POST',
                headers: new Headers(),
                body: formData
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error();
                }
            }).then((data) => {
                if (data.statusCode === 200) {
                    if (create && !saveExit) {
                        this.props.handleNewImageSave(data.data.image_id);
                    } else {
                        if (saveExit) {
                            this.props.handleSaveExit();
                        }
                    }
                } else {
                    throw Error();
                }
            }).catch((e) => {
                console.log(e.toString())
                // todo handle this and other errors/point of failure
                this.setState({
                    error: "Something went wrong. Please try again later."
                });
            });
        } else {
            // alert("handle no auth error");
        }
    }

    checkFormValues = () => {
        let form_error = false
        let error_fields = []
        for (let key in this.state) {
            if (key.includes('image_')) {
                if (this.state[key] === "" && key !== "image_id" && !this.state.allowed_empty.includes(key)) {
                    error_fields.push(key)
                    form_error = true
                    window.scrollTo(0, 0)
                } else {
                    error_fields.filter((field) => {
                        return field !== key
                    });
                }
            }
        }

        if (form_error && error_fields.length !== 0) {
            this.setState({
                error: "Please make sure all required fields are filled.",
                error_fields: error_fields
            })
        }

        return form_error
    }

    handleNewSave = () => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(true, false)
            this.props.handleSaveNotification()
        }
    }

    handleNewSaveExit =() => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(true, true);
        }
    }

    handleSave = (e) => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(false, false)
            this.props.handleSaveNotification()
        }
    }

    handleSaveExit = (e) => {
        let form_error = this.checkFormValues()
        if (!form_error) {
            this.doSave(false, true)
        }
    }

    handleDelete = (e) => {
        if (localStorage.getItem('aawt')) {
            if (window.confirm("Are you sure you want to delete '" + this.state.image_name + "'.\nThis can not be undone")) {
                let formData = new FormData();
                formData.append('action', 'delete-image');
                formData.append('token', localStorage.getItem('aawt'))
                formData.append('image_id', this.state.image_id);

                fetch(this.state.url, {
                    method: 'POST',
                    headers: new Headers(),
                    body: formData
                }).then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw Error();
                    }
                }).then((data) => {
                    if (data.statusCode === 200) {
                        this.props.handleDeletedImage();
                    } else {
                        //todo handle this error
                        throw Error()
                    }
                }).catch((err) => {
                    console.log(err)
                    this.setState({
                        error: "Something went wrong. Please try again later."
                    });
                });
            }
        }
    }

    handleErrorFieldChange = (field) => {
        if (this.state.error !== "" && this.state.error_fields.length !== 0) {
            if (field.target.value !== "") {
                field.target.style.borderColor = "black";
                this.setState({
                    error_fields: this.state.error_fields.filter((error_field) => {
                        return error_field !== field.target.id.replace('form-', '')
                    })
                })
            }
        }
    }

    render() {

        let show_delete;
        let handleSave;
        let handleSaveExit;
        if (this.props.action === "create") {
            show_delete = false
            handleSave = this.handleNewSave;
            handleSaveExit = this.handleNewSaveExit;
        } else {
            show_delete = true;
            handleSave = this.handleSave;
            handleSaveExit = this.handleSaveExit
        }

        return (
            <div className="page-heading-container">
                <h2 className="sub-heading">{this.state.image_name}</h2>
                <ImageForm
                    action={this.props.action}
                    id={this.state.image_id}
                    name={this.state.image_name}
                    handleNameChange={this.handleImageNameChange}
                    order={this.state.image_order}
                    handleOrderChange={this.handleImageOrderChange}
                    image={this.state.image_image}
                    handleImageChange={this.handleImageImageChange}
                    handleSave={handleSave}
                    handleSaveExit={handleSaveExit}
                    showDelete={show_delete}
                    handleDelete={this.handleDelete}
                    error={this.state.error}
                    error_fields={this.state.error_fields}
                    handleErrorFieldChange={this.handleErrorFieldChange}
                />
            </div>
        );
    }

}

export default Image;
