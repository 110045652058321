import React from 'react';
import './BookingConf.css'
import Buttons from "../common/button/Buttons";

class BookingConf extends React.Component {

    render() {
        return (
            <div className="booking-conf-page-container">
                <h1 className="booking-conf-title">Booking Confirmed!</h1>
                <h2 className="booking-conf-subheading">We have received your booking and reserved you a slot.</h2>
                <h2 className="booking-conf-subheading">You should receive your booking confirmation via text message shortly.</h2>
                <h2 className="booking-conf-subheading">Please contact us as repeatforkids@gmail.co.uk if this isn't the case</h2>
                <div className="home-buttons">
                    <Buttons to="/" btn_text="Homepage" link={true}/>
                    {/*<Buttons to="/about-us" btn_text="Contact Us" link={true}/>*/}
                </div>
            </div>
        );
    }
}

export default BookingConf;