import React from 'react';
import './Gallery.css'
// import SessionCard from "../common/cards/Card";
import PhotoAlbum from "react-photo-album";
import {forEach} from "react-bootstrap/ElementChildren";
import {compareArraysAsSet} from "@testing-library/jest-dom/dist/utils";


class GalleryHome extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// url: "http://localhost/rfk-rest/rfk-api/api/gallery",
			url: 'https://api.repeatforkids.co.uk/api/gallery',
			page: "home",
			chosen_image: [],
			data: [],
			gallery_data: []
		}

	}

	componentDidMount() {
		this.getImages();
	}

	getImages = () => {
		let formData = new FormData();
		formData.append('action', 'get-images');

		fetch(this.state.url, {
			method: 'POST',
			headers: new Headers(),
			body: formData
		}).then( (response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				throw Error();
			}
		}).then( (data) => {
			if (data.statusCode === 200) { this.prepareImages(data.data) } else { throw Error() }
		}).catch( (e) => {
			this.setState({
				error: "Something went wrong. Please try again later."
			});
		});

	}

	prepareImages = (images) => {
		console.log(images);
		// this.setState({data: data.data, page: "home"})
		let gallery_data = [];
		let i = 0;
		for (const image of images) {
			let img = new Image();
			img.src = image.image_image;
			// console.log(image.image_image)
			gallery_data.push({src: img.src, key: i, width: 50, height: 50});
			i++;
		}
		console.log(gallery_data);
		this.setState({gallery_data: gallery_data, page: "home"})

	}

	handleClick = (event_data) => {
		alert("Replace these cards with images - make the image larger on click?")
	}

	render() {

		let data = {
			id: 1,
			title: "Image 1",
			slots: 1,
			date: "image 1",
			time: "image 1",
			price: 3.0,
		}

		// const photos = [
		// 	{
		// 		src: 'C:/xampp/htdocs/rfk-rest/rfk-react/src/app/assets/static/imgs/parent-card-icon.png',
		// 		width: 800,
		// 		height: 600
		// 	},
		// 	{
		// 		src: "C:/xampp/htdocs/rfk-rest/rfk-react/src/app/assets/static/imgs/session_img.jpg",
		// 		width: 1600,
		// 		height: 900
		// 	}
		// ];

		return (
			<div className="gallery-page-container">
				<h1 className="gallery-page-title">Gallery</h1>
				<div className="gallery-container">
					{console.log(this.state.gallery_data)}
					<PhotoAlbum layout="rows" photos={this.state.gallery_data} />

					{/*<SessionCard data={data} handleClick={this.handleClick}/>*/}
					{/*<SessionCard data={data} handleClick={this.handleClick}/>*/}
					{/*<SessionCard data={data} handleClick={this.handleClick}/>*/}
					{/*<SessionCard data={data} handleClick={this.handleClick}/>*/}
				</div>
			</div>
		);
	}

}

export default GalleryHome;