import React, {Component} from 'react';
import './Checkout.css'
import SessionCard from "../../common/cards/session-card/SessionCard";
import DataCard from "../../common/cards/data-card/DataCard";
import Paypal from "../paypal/Paypal";
import {Navigate} from 'react-router-dom';

class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // url: 'http://localhost/rfk-rest/rfk-api/api/orders',
            url: 'https://api.repeatforkids.co.uk/api/orders',
            session_data: this.props.data.session_data,
            child_data: [],
            parent_data: [],
            completed: false
        }
    }

    componentDidMount() {
        this.getChildData()
        this.getParentData();
    }

    getChildData = () => {
        let data = []

        for (let i = 0; i < 5; i++) { // max of 5 per booking
            if (this.props.data['name_' + (i+1)] !== "" && this.props.data['age_' + (i+1)] !== "" && (i+1) <= this.props.data.child_num) {
                data.push({
                    id: i+1,
                    name: this.props.data['name_' + (i + 1)],
                    age: this.props.data['age_' + (i + 1)]
                })
            }
        }

        this.setState({
            child_data: data
        })
    }

    getParentData = () => {
        this.setState( {
            parent_data: {
                name: this.props.data.parent_name,
                email: this.props.data.email,
                num: this.props.data.num,
                med_info: this.props.data.med_info,
                add_info: this.props.data.add_info
            }
        })

    }

    handlePayment = (details) => {
        // send all the stuff to the api
        this.doSave(this.prepareOrderDetails(details))
    }

    prepareOrderDetails = (details) => {
        let child_name_2 = ""
        let child_age_2 = ""
        if (this.state.child_data[1] !== undefined) {
            child_name_2 = this.state.child_data[1].name
            child_age_2 = this.state.child_data[1].age
        }

        let child_name_3 = ""
        let child_age_3 = ""
        if (this.state.child_data[2] !== undefined) {
            child_name_3 = this.state.child_data[2].name
            child_age_3 = this.state.child_data[2].age
        }

        let child_name_4 = ""
        let child_age_4 = ""
        if (this.state.child_data[3] !== undefined) {
            child_name_4 = this.state.child_data[3].name
            child_age_4 = this.state.child_data[3].age
        }

        let child_name_5 = ""
        let child_age_5 = ""
        if (this.state.child_data[4] !== undefined) {
            child_name_5 = this.state.child_data[4].name
            child_age_5 = this.state.child_data[4].age
        }

        return {
            order_parent: this.state.parent_data.name,
            order_email: this.state.parent_data.email,
            order_contact: this.state.parent_data.num,
            order_paypal_id: details.id,
            order_paypal_payer_id: details.payer.payer_id,
            order_session_id: this.state.session_data.session_id,
            order_child_name_1: this.state.child_data[0].name,
            order_child_age_1: this.state.child_data[0].age,
            order_child_name_2: child_name_2,
            order_child_age_2: child_age_2,
            order_child_name_3: child_name_3,
            order_child_age_3: child_age_3,
            order_child_name_4: child_name_4,
            order_child_age_4: child_age_4,
            order_child_name_5: child_name_5,
            order_child_age_5: child_age_5,
            order_child_num: this.state.child_data.length,
            order_med_info: this.state.parent_data['med_info'],
            order_add_info: this.state.parent_data['add_info'],
            order_photo_consent: this.props.data['consent'] === undefined ? 'No' : this.props.data['consent'],
        }
    }

    doSave = (data) => {
        let formData = new FormData();
        formData.append('action', "create-order")
        formData.append('order_parent', data.order_parent)
        formData.append('order_email', data.order_email)
        formData.append('order_contact', data.order_contact)
        formData.append('order_paypal_id', data.order_paypal_id)
        formData.append('order_paypal_payer_id', data.order_paypal_payer_id)
        formData.append('order_session_id', data.order_session_id)
        formData.append('order_child_name_1', data.order_child_name_1)
        formData.append('order_child_age_1', data.order_child_age_1)
        formData.append('order_child_name_2', data.order_child_name_2)
        formData.append('order_child_age_2', data.order_child_age_2)
        formData.append('order_child_name_3', data.order_child_name_3)
        formData.append('order_child_age_3', data.order_child_age_3)
        formData.append('order_child_name_4', data.order_child_name_4)
        formData.append('order_child_age_4', data.order_child_age_4)
        formData.append('order_child_name_5', data.order_child_name_5)
        formData.append('order_child_age_5', data.order_child_age_5)
        formData.append('order_child_num', data.order_child_num)
        formData.append('order_med_info', data.order_med_info)
        formData.append('order_add_info', data.order_add_info)
        formData.append('order_photo_consent', data.order_photo_consent)


        fetch(this.state.url, {
            method: 'POST',
            mode: 'no-cors',
            headers: new Headers(),
            body: formData
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw Error();
            }
        }).then((data) => {
            if (data.statusCode === 200) {
                this.setState({completed: true})
            } else {
                throw Error();
            }
        }).catch((e) => {
            console.log(e.toString())
            this.setState({completed: true}) // this is so the 504 doesnt happen (send an email to mam to let her know something went wrong )
            // todo handle this and other errors/point of failure
            this.setState({
                error: "Something went wrong. Please try again later."
            });
        });
    }

    render() {
        let content = ""
        let redirect = ""

        if (this.state.completed) {
            content = ""
            redirect = <Navigate replace to='/booking-confirmation' />
        } else {
            redirect = ""
            content = (
                <div className="checkout-page-container">
                    <div className="summary-container">
                        <h1 className="checkout-title">Booking Summary</h1>

                        <div className="summary-content">
                            <h2 className="summary-sub-title">Child Info</h2>
                            {this.state.child_data.map((data) => (<DataCard key={data['id']} data={data} type="child-data" />))}
                        </div>

                        <div className="summary-content">
                            <h2 className="summary-sub-title">Parent Info</h2>
                            <DataCard data={this.state.parent_data} type="parent-data"/>
                        </div>

                        <div className="summary-content">
                            <h2 className="summary-sub-title">Medical/Allergy Info</h2>
                            <DataCard data={this.props.data['med_info']} type="med-data"/>
                        </div>

                        <div className="summary-content">
                            <h2 className="summary-sub-title">Additional Info</h2>
                            <DataCard data={this.props.data['add_info']} type="add-data"/>
                        </div>

                        <div className="summary-content">
                            <h2 className="summary-sub-title">Photo Consent</h2>
                            <DataCard data={this.props.data['consent']} type="photo-data"/>
                        </div>

                        <div className="summary-content booking-info-summary">
                            <h2 className="summary-sub-title">Booking Info</h2>
                            <SessionCard data={this.state.session_data} display_only={true} />
                        </div>
                    </div>
                    <div className="checkout-container">
                        <h1 className="checkout-title">Checkout</h1>
                        {/* todo make the to pay notice a card variant*/}
                        <DataCard data={this.props.data.session_data.session_price * this.state.child_data.length} type="price-data"/>
                        <div className="paypal-container">
                            <Paypal price={this.props.data.session_data.session_price * this.state.child_data.length} handlePayment={this.handlePayment}/>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                {content}
                {redirect}
            </div>
        )
    }

}

export default Checkout;