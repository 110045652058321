import React from 'react';
import './Notification.css';

class Notification extends React.Component {

    render() {
        let notification_styling = "notification-text-container";
        let notification_container_styling = "notification-container";
        let notification_text_styling = "";

        if (this.props.type === "success") {
            notification_styling += " success"
        } else {
            notification_styling += " error"
        }

        if (this.props.fade) {
            notification_container_styling += " fade"
        }

        if (this.props.form_notification) {
            notification_container_styling += " form-notification"
            notification_styling += " form-error"
            notification_text_styling += " form-error-text"
        } else {
            window.scrollTo(0, 0)
        }

        return(
            <div className={notification_container_styling}>
                <div className={notification_styling}>
                    <h3 className={notification_text_styling}>{this.props.text}</h3>
                </div>
            </div>
        );
    }

}

export default Notification;