import React, {Component} from 'react';
import Login from "./common/login/Login";
import Navigation from "./common/navigation/Navigation";
import Header from "./common/header/Header";
import Dashboard from "./home/Dashboard";
import EventsPage from "./events/EventsPage";
import './Admin.css';
import GalleryPage from "./gallery/GalleryPage";
import BookingsPage from "./bookings/BookingsPage";
import SessionsPage from "./sessions/SessionsPage";
import ReviewsPage from "./reviews/ReviewsPage";
import ToddlerSessionsPage from './toddler-sessions/ToddlerSessionsPage';

class AdminRouter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // url: 'http://localhost/rfk-rest/rfk-api/api/authenticate',
            url: 'https://api.repeatforkids.co.uk/api/authenticate',
            authenticated: false,
            username: "",
            password: "",
            token: null,
            login_error: "",
            user: "",
        };

    }

    componentDidMount() {
        if (localStorage.getItem('aawt')) {
            let formData = new FormData();
            formData.append('token', localStorage.getItem('aawt'));
            formData.append('admin', true);

            fetch(this.state.url, {
                method: 'POST',
                headers: new Headers(),
                body: formData
            }).then( (response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error();
                }
            }).then( (data) => {
                if (data.statusCode !== 200) {
                    localStorage.removeItem('aawt');
                    this.setState({
                        authenticate: false,
                        login_error: "You have been logged out to keep your account secure. Please login again.",
                        user: ""
                    });
                } else {
                    this.setState({
                        authenticated: true,
                        token: data.data.token,
                        user: data.data.username
                    });
                }
            }).catch( () => {
                this.setState({
                    authenticated: false,
                    login_error: "Something went wrong. Please try again later.",
                    user: ""
                });
            });
        }
    }

    handleUsername = (e) => {
        this.setState({username: e.target.value});
    }

    handlePassword = (e) => {
        this.setState({password: e.target.value});
    }

    handleLoginClick = () => {
        if (this.state.username !== "" && this.state.password !== "") {
            let formData = new FormData();
            formData.append('username', this.state.username);
            formData.append('password', this.state.password);
            formData.append('admin', true);

            fetch(this.state.url, {
                method: 'POST',
                headers: new Headers(),
                body: formData
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw Error();
                }
            }).then((data) => {
                if (data.statusCode === 200) {
                    if ('token' in data.data) {
                        this.setState({
                            authenticated: true,
                            token: data.data.token,
                            user: data.data.username,
                        });

                        localStorage.setItem('aawt', data.data.token);
                    }
                } else if (data.statusCode === 401) {
                    let error = data.message.split(".")[0];
                    if (error === "Incorrect username or password") {
                        this.setState({login_error: error});
                    }
                }
            }).catch((e) => {
                this.setState({
                    authenticated: false,
                    login_error: "Something went wrong.\n Please try again later.",
                    user: ""
                });
            });
        } else {
            this.setState({login_error: "Please enter both an username and password"});
        }

    }

    handleLogoutClick = () => {
        if (window.confirm("Are you sure you want to logout?")) {
            this.setState({
                authenticated: false,
                token: null,
                login_error: "",
                user: ""
            })

            localStorage.removeItem('aawt');
        }
    }

    render() {
        let page;
        let page_structure;
        let login_form;
        if (this.state.authenticated) {
            page_structure = (
                <div>
                    <Navigation />
                    <Header handleLogoutClick={this.handleLogoutClick}/>
                </div>
            )


            if (this.props.page === "bookings") {
                page = <BookingsPage />
            } else if (this.props.page === "sessions") {
                page = <SessionsPage />            
            } else if (this.props.page === "private-hires") {
                page = <ToddlerSessionsPage />
            } else if (this.props.page === "general-play-sessions") {
                page = <EventsPage/>
            } else if (this.props.page === "gallery") {
                page = <GalleryPage />
            } else if (this.props.page === "reviews") {
                page = <ReviewsPage />
            } else {
                page = <Dashboard user={this.state.user} />
            }

        } else {
            login_form = (<div className="login-background">
                <Login
                    username={this.state.username}
                    handleUsername={this.handleUsername}
                    password={this.state.password}
                    handlePassword={this.handlePassword}
                    handleLoginClick={this.handleLoginClick}
                    error={this.state.login_error}
                />
            </div>)
        }

        return (
            <div className="admin-page">
                {login_form}
                {page_structure}
                {page}
            </div>
        );
    }

}

export default AdminRouter;